import React from "react";
import UserFeeds from "./userFeeds/UserFeeds";
import Recruiter from "./recruiterFeeds/RecruiterFeeds"
import { useSelector } from "react-redux";

const UserHome = () => {

  const user = useSelector(state => state.auth.userInfo);
  // console.log(user, "from hommmmeee");
  
  return (
    <div className="user_home_container">
      {user?.role === "user" ? <UserFeeds /> : <Recruiter/>}
    </div>
  );
};

export default UserHome;
