// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/riso_hire_background_img.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero_container {
    height: 90vh;
    width: 100%;
    background-image: linear-gradient(to right, rgba(31, 160, 148, 0.5), rgba(0, 154, 162, 0.5),  rgba(0, 146, 174, 0.5), rgba(0, 137, 182, 0.5), rgba(0, 126, 185, 0.5));
    
    
}

  
.hero_container:before {
    content: "";
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center/cover;
    position: absolute;
    width: 100%;
    height: 90vh;
    z-index: -1;
}

@media screen and (max-width:768px) {
    .hero_container {
        width: 100vw;
        height: 80vh;
    }
    .hero_container:before {
        width: 100vw;
        height: 80vh;
    }
}

@media(max-width:565px) {
    .hero_container {
        position: relative;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 35, 82, 0.7);
    }
    .hero_container::before {
        content: "";
        background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center/cover;
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: -1;
    }
}`, "",{"version":3,"sources":["webpack://./src/containers/hero/Hero.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,qKAAqK;;;AAGzK;;;AAGA;IACI,WAAW;IACX,iFAAiG;IACjG,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;IAChB;IACA;QACI,YAAY;QACZ,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,aAAa;QACb,WAAW;QACX,sCAAsC;IAC1C;IACA;QACI,WAAW;QACX,iFAAiG;QACjG,kBAAkB;QAClB,WAAW;QACX,aAAa;QACb,WAAW;IACf;AACJ","sourcesContent":[".hero_container {\r\n    height: 90vh;\r\n    width: 100%;\r\n    background-image: linear-gradient(to right, rgba(31, 160, 148, 0.5), rgba(0, 154, 162, 0.5),  rgba(0, 146, 174, 0.5), rgba(0, 137, 182, 0.5), rgba(0, 126, 185, 0.5));\r\n    \r\n    \r\n}\r\n\r\n  \r\n.hero_container:before {\r\n    content: \"\";\r\n    background: url(\"../../assets/images/riso_hire_background_img.png\") no-repeat center center/cover;\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 90vh;\r\n    z-index: -1;\r\n}\r\n\r\n@media screen and (max-width:768px) {\r\n    .hero_container {\r\n        width: 100vw;\r\n        height: 80vh;\r\n    }\r\n    .hero_container:before {\r\n        width: 100vw;\r\n        height: 80vh;\r\n    }\r\n}\r\n\r\n@media(max-width:565px) {\r\n    .hero_container {\r\n        position: relative;\r\n        height: 100vh;\r\n        width: 100%;\r\n        background-color: rgba(0, 35, 82, 0.7);\r\n    }\r\n    .hero_container::before {\r\n        content: \"\";\r\n        background: url(\"../../assets/images/riso_hire_background_img.png\") no-repeat center center/cover;\r\n        position: absolute;\r\n        width: 100%;\r\n        height: 100vh;\r\n        z-index: -1;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
