import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: "/recruiter/signup",
        method: "POST",
        body: data,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "/recruiter/signin",
        method: "POST",
        body: data,
      }),
    }),

    candidateLogin: builder.mutation({
      query: (data) => ({
        url: "/signin",
        method: "POST",
        body: data,
      }),
    }),
    candidateRegister: builder.mutation({
      query: (data) => ({
        url: "/signup",
        method: "POST",
        body: data,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
        url: "/verify",
        method: "POST",
        body: data,
      }),
    }),
    resendOtp: builder.mutation({
      query: (data) => ({
        url: "/reverify",
        method: "POST",
        body: data,
      }),
    }),
    basicDet: builder.mutation({
      query: (data) => ({
        url: "/user/basic/update",
        method: "POST",
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/forgot-password",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "/reset-password",
        method: "POST",
        body: data,
      }),
    }),
    jobStatus: builder.mutation({
      query: (data) => ({
        url: "/job/status-new",
        method: "POST",
        body: data,
      }),
    }),
    skillDet: builder.query({
      query: () => ({
        url: "/category/getCategories",
        method: "GET",
      }),
    }),
    popularDet: builder.query({
      query: () => ({
        url: "/popular-categories",
        method: "GET",
      }),
    }),
    trendingDet: builder.query({
      query: () => ({
        url: "/trending-jobs",
        method: "GET",
      }),
    }),
    courseTypeDet: builder.query({
      query: () => ({
        url: "/course-type",
        method: "GET",
      }),
    }),
    typeDet: builder.query({
      query: () => ({
        url: "/types/getTypes",
        method: "GET",
      }),
    }),
    instituteDet: builder.query({
      query: () => ({
        url: "/institute",
        method: "GET",
      }),
    }),
    courseDet: builder.query({
      query: () => ({
        url: "/course",
        method: "GET",
      }),
    }),
    jobAll: builder.query({
      query: () => ({
        url: "/job/get",
        method: "GET",
      }),
    }),
    recJob: builder.query({
      query: () => ({
        url: "/job/recommended",
        method: "GET",
      }),
    }),
    appliedJobsMy: builder.query({
      query: () => ({
        url: "/job/applied/get",
        method: "GET",
      }),
    }),
    savedJobsMy: builder.query({
      query: () => ({
        url: "/job/save/get",
        method: "GET",
      }),
    }),
    savedCandidateMy: builder.query({
      query: () => ({
        url: "/user/save/get",
        method: "GET",
      }),
    }),
    feedbackGet: builder.query({
      query: () => ({
        url: "/get-feedback",
        method: "GET",
      }),
    }),
    jobDet: builder.query({
      query: (reqId) => `/my-jobs/${reqId}`,
    }),
    jobDetById: builder.query({
      query: (jobId) => `/getjobby/${jobId}`,
    }),
    jobDetByIdUser: builder.query({
      query: (jobId) => `/getjobuser/${jobId}`,
    }),
    specialDet: builder.query({
      query: (courseId) => `getSpecial/${courseId}`,
    }),
    skillAdd: builder.mutation({
      query: (data) => ({
        url: "/user/skill/update",
        method: "POST",
        body: data,
      }),
    }),
    comapnydotAdd: builder.mutation({
      query: (data) => ({
        url: "/company/add/det",
        method: "POST",
        body: data,
      }),
    }),
    uplaodResumeAdd: builder.mutation({
      query: (formData) => ({
        url: "/job/update-resume",
        method: "POST",
        body: formData,
      }),
    }),
    uplaodProfileAdd: builder.mutation({
      query: (formData) => ({
        url: "/profile/picture/update/new",
        method: "POST",
        body: formData,
      }),
    }),
    jobAdd: builder.mutation({
      query: (data) => ({
        url: "/job/create",
        method: "POST",
        body: data,
      }),
    }),
    currentCompany: builder.mutation({
      query: (data) => ({
        url: "/user/emp/update",
        method: "POST",
        body: data,
      }),
    }),
    projectAdd: builder.mutation({
      query: (data) => ({
        url: "/user/project/update",
        method: "POST",
        body: data,
      }),
    }),
    personalAdd: builder.mutation({
      query: (data) => ({
        url: "/user/personal/update",
        method: "POST",
        body: data,
      }),
    }),
    careerAdd: builder.mutation({
      query: (data) => ({
        url: "/user/carrer/update",
        method: "POST",
        body: data,
      }),
    }),
    skillRemove: builder.mutation({
      query: (data) => ({
        url: "/user/skill/remove",
        method: "POST",
        body: data,
      }),
    }),
    educationUpdate: builder.mutation({
      query: (data) => ({
        url: "/user/education/update",
        method: "POST",
        body: data,
      }),
    }),
    educationDelete: builder.mutation({
      query: (data) => ({
        url: "/user/education/delete",
        method: "POST",
        body: data,
      }),
    }),
    empDelete: builder.mutation({
      query: (data) => ({
        url: "/user/emp/delete",
        method: "POST",
        body: data,
      }),
    }),
    proDelete: builder.mutation({
      query: (data) => ({
        url: "/user/pro/delete",
        method: "POST",
        body: data,
      }),
    }),
    educationEdit: builder.mutation({
      query: (data) => ({
        url: "/user/education/edit",
        method: "POST",
        body: data,
      }),
    }),
    projectEdit: builder.mutation({
      query: (data) => ({
        url: "/user/projects/edit",
        method: "POST",
        body: data,
      }),
    }),

    employementEdit: builder.mutation({
      query: (data) => ({
        url: "/user/workdata/edit",
        method: "POST",
        body: data,
      }),
    }),
    instituteUpdate: builder.mutation({
      query: (data) => ({
        url: "/institute/create",
        method: "POST",
        body: data,
      }),
    }),
    applyJob: builder.mutation({
      query: (id) => ({
        url: `/job/apply/${id}`,
        method: "POST",
        body: id,
      }),
    }),
    feedback: builder.mutation({
      query: (data) => ({
        url: `/feedback`,
        method: "POST",
        body: data,
      }),
    }),
    searchJob: builder.mutation({
      query: (data) => ({
        url: `/job/find/skill`,
        method: "POST",
        body: data,
      }),
    }),
    findSkilledJobs: builder.mutation({
      query: (data) => ({
        url: `/find/skilled/job`,
        method: "POST",
        body: data,
      }),
    }),
    visitUser: builder.mutation({
      query: (data) => ({
        url: `/user/visit`,
        method: "POST",
        body: data,
      }),
    }),
    profileComp: builder.mutation({
      query: (data) => ({
        url: `/profile-comp`,
        method: "POST",
        body: data,
      }),
    }),
    saveJob: builder.mutation({
      query: (data) => ({
        url: `/job/save`,
        method: "POST",
        body: data,
      }),
    }),
    unsaveJob: builder.mutation({
      query: (data) => ({
        url: `/job/unsave`,
        method: "POST",
        body: data,
      }),
    }),
    saveCandidate: builder.mutation({
      query: (data) => ({
        url: `/user/saving`,
        method: "POST",
        body: data,
      }),
    }),
    statusJob: builder.mutation({
      query: (data) => ({
        url: `/status/jobchange`,
        method: "POST",
        body: data,
      }),
    }),
    courseUpdate: builder.mutation({
      query: (data) => ({
        url: "/course/create",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useCandidateLoginMutation,
  useCandidateRegisterMutation,
  useVerifyOtpMutation,
  useBasicDetMutation,
  useSkillDetQuery,
  useSkillAddMutation,
  useSkillRemoveMutation,
  useEducationUpdateMutation,
  useTypeDetQuery,
  useInstituteDetQuery,
  useInstituteUpdateMutation,
  useCourseDetQuery,
  useCourseUpdateMutation,
  useSpecialDetQuery,
  useCourseTypeDetQuery,
  useCurrentCompanyMutation,
  useProjectAddMutation,
  usePersonalAddMutation,
  useCareerAddMutation,
  useJobDetQuery,
  useJobAddMutation,
  useJobAllQuery,
  useApplyJobMutation,
  useSearchJobMutation,
  useVisitUserMutation,
  useRecJobQuery,
  useProfileCompMutation,
  useSaveJobMutation,
  useAppliedJobsMyQuery,
  useSavedJobsMyQuery, 
  useUplaodResumeAddMutation,
  useUplaodProfileAddMutation,
  useComapnydotAddMutation,
  useStatusJobMutation,
  useJobDetByIdQuery,
  useSaveCandidateMutation,
  useSavedCandidateMyQuery,
  useUnsaveJobMutation,
  useJobDetByIdUserQuery,
  useEducationDeleteMutation,
  useEducationEditMutation,
  useEmpDeleteMutation,
  useProDeleteMutation,
  useFindSkilledJobsMutation,
  usePopularDetQuery,
  useTrendingDetQuery,
  useFeedbackMutation,
  useFeedbackGetQuery,
  useProjectEditMutation,
  useEmployementEditMutation,
  useJobStatusMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation
} = userApiSlice;
