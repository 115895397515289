import React from "react";
import "./MobileHeader.css";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../../assets/images/hire_form_logo.png";
const MobileHeader = () => {
  return (
    <div className="mobile_header_section">
      <div className="mobile_header_container ">
        <div className="mobile_header_heading_section">
          <div className="mobile_header_logo_section">
            <img src={logo} alt="logo" />
          </div>
          <div className="mobile_header_heading_text_section">
            <h1>Discover your next career move!</h1>
            <p>5 lakh+ jobs for you to explore</p>
          </div>
        </div>
        <div className="mobile_search_and_buttons">
          <div className="mobile_header_search_section">
            <FontAwesomeIcon
              className="mobile_search_icon"
              icon={faMagnifyingGlass}
            />
            <input placeholder="Search jobs here.." />
          </div>
          {/* <div className="mobile_header_button_section">
            <button className="m_clogin_button">Candidate Login</button>
            <button className="m_elogin_button">Employer Login</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
