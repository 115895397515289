import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobData: [], // Initial state
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setJobData: (state, action) => {
      state.jobData = Array.isArray(action.payload) ? action.payload : [action.payload];
    },
    updateJobApplicant: (state, action) => {
      const { jobId, applicant } = action.payload;
       console.log(state.jobData);
       
      // Make sure not to mutate state directly
      const jobIndex = state.jobData.findIndex(job => job._id === jobId);
      if (jobIndex !== -1) {
        const applicantIndex = state.jobData[jobIndex].applicant.findIndex(app => app.userId === applicant.userId);
        if (applicantIndex !== -1) {
          // Update applicant's status immutably
          state.jobData[jobIndex].applicant[applicantIndex] = {
            ...state.jobData[jobIndex].applicant[applicantIndex],
            status: applicant.status
          };
        }
      }

      console.log(state.jobData, "update");
      
    },
  },
});

export const { setJobData, updateJobApplicant } = jobSlice.actions;
export default jobSlice.reducer;
