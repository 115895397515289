import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./reco.css";
import UserHeader from '../userHome/userHeader/UserHeader';
import { useAppliedJobsMyQuery, useApplyJobMutation, useRecJobQuery, useSavedJobsMyQuery, useSaveJobMutation } from '../../slice/authApiSlice';
import { setJobData, updateJobApplicant } from '../../slice/jobSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faBookmark, faSave } from "@fortawesome/free-solid-svg-icons";
import { FaBookmark, FaRegBookmark } from 'react-icons/fa6';
import { setCredentials } from '../../slice/authSlice';
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import cImg from "../../assets/company.png";
import { generatePublicUrl } from '../../constants';


function Reco() {
    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const [jobs, setJobs] = useState([]);  // Initialize with an empty array
    // const { data: savedJobs, isLoading, isError, refetch } = useSavedJobsMyQuery();
    const {data: recJob, isLoading: recLoading} = useRecJobQuery();


    // console.log(recJob);
    
    var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span className="newBiddingTimer">Job Expired</span>
        } else {
          return <span className="newBiddingTimer">{days}d  {hours}h {minutes}m {seconds}s left</span>;
        }
      };
    
    useEffect(() => {
        if (recJob && Array.isArray(recJob.jobs)) {
            setJobs(recJob.jobs);
        }
    }, [recJob]);
    

    

    const [currentPage, setCurrentPage] = useState(1);
    const jobsPerPage = 8;


    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);


    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(jobs.length / jobsPerPage);

    
    const visitJobData = (data) => {
        navigate(`/job/${data._id}/details`, { state: { data } });
    }
   

    return (
        
        <div className='jobpage'>
            <div>
                <UserHeader />
                <h2 className='latest-header-job-design'>Recommened jobs ({jobs?.length})</h2>
            </div>
          {jobs.length === 0 ? 
            <section className='job-outer1'>
                <h2>not yet save any jobs</h2>
                <button>Save now</button>
            </section>  :
            <>
              <section className='job-outer'>
                <div className='job-inner-page'>
                    {currentJobs?.map(jb => (
                        <div className='job-card' key={jb._id}>
                            <div className='jd-outer'>
                                <div className='job-img-det'>
                                    {jb?.companyDetails.companyLogo === "" ? <img src={cImg}/> : <img src={generatePublicUrl(jb?.companyDetails.companyLogo)}/> }
                                </div>
                                <div className='jd-outer-det-new'>
                                    <h2>{jb.title.length > 20 ? `${jb.title.slice(0, 20)}...` : jb.title}</h2>
                                    <p>{jb?.description.length > 52 ? `${jb?.description.slice(0, 52)}...` : jb?.description }</p>
                                    <div className='jdd-btn'>
                                        <button onClick={() => visitJobData(jb)}>See Details</button>
                                        {/* <p></p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <div className="pagination">
                {[...Array(totalPages).keys()].map(pageNumber => (
                    <button
                        key={pageNumber + 1}
                        onClick={() => paginate(pageNumber + 1)}
                        className={currentPage === pageNumber + 1 ? 'active' : ''}
                    >
                        {pageNumber + 1}
                    </button>
                ))}
            </div>
            </>
        }
        </div>
    );
}

export default Reco;
