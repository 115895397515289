import React, { useState, useEffect } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { setCredentials } from "../../slice/authSlice";
import { useProjectAddMutation, useProjectEditMutation } from "../../slice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

const ProjectEdit = ({ onClose, onSubmit, initialData }) => {
  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token);
  const [projectEdit, { isLoading }] = useProjectEditMutation();
  const dispatch = useDispatch();

  // console.log(initialData);
  

  const [formData, setFormData] = useState({
    projectName: initialData.projectName || "",
        projectDesc: initialData.projectDesc || "",
        client: initialData.client || "",
        projectStatus: initialData.projectStatus || "",
        workFrom: moment(initialData.workFrom).format("YYYY-MM-DD") || "",
        workTill: moment(initialData.workTill).format("YYYY-MM-DD") || "",
        location: initialData.location || "",
        projectSite: initialData.projectSite || "",
        natureEmp: initialData.natureEmp || "",
        teamSize: initialData.teamSize || "",
        roleInProject: initialData.roleInProject || "",
        skill: initialData.skill || "",
        projectId :initialData._id
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {

    
    const {
      projectId,  
      projectName,
      projectDesc,
      client,
      projectStatus,
      workFrom,
      workTill,
      location,
      projectSite,
      natureEmp,
      teamSize,
      roleInProject,
      skill,
    } = formData;

    // console.log(formData);
    
    if (
      !projectName ||
      !projectDesc ||
      !client ||
      !projectStatus ||
      !workFrom ||
      !location ||
      !natureEmp ||
      !teamSize ||
      !roleInProject ||
      !skill ||
      (projectStatus !== "inprogress" && !workTill)
    ) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const res = await projectEdit(formData).unwrap();
      dispatch(setCredentials({ userInfo: res.updatedUser, token: token }));
      onSubmit();
    } catch (error) {
      toast.error("Failed to add project.");
    }
  };

  return (
    <div className="modal_container">
      <div className="modal_content">
        <div className="modal_x_button">
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="modal_text_content">
          <div className="resume_headline_text">
            <h1>Project Details</h1>
            <p>Fill Your Project Details.</p>
          </div>
          <section className="edu-inner">
            <div className="input_group">
              <input
                type="text"
                name="projectName"
                placeholder="Project Name"
                value={formData.projectName}
                onChange={handleInputChange}
              />
              <textarea
                name="projectDesc"
                placeholder="Project Description"
                value={formData.projectDesc}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="client"
                placeholder="Client"
                value={formData.client}
                onChange={handleInputChange}
              />
              <select
                name="projectStatus"
                value={formData.projectStatus}
                onChange={handleInputChange}
              >
                <option value="">Select project status</option>
                <option value="inprogress">In Progress</option>
                <option value="finished">Finished</option>
              </select>
              <input
                placeholder="Enter work from"
                className="textbox-n"
                name="workFrom"
                type="text"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                id="date"
                value={formData.workFrom}
                onChange={handleInputChange}
              />

              {/* Conditionally render Work Till input */}
              {formData.projectStatus !== "inprogress" && (
                <input
                  placeholder="Enter work till"
                  className="textbox-n"
                  name="workTill"
                  type="text"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  id="date"
                  value={formData.workTill}
                  onChange={handleInputChange}
                />
              )}

              <input
                type="text"
                name="location"
                placeholder="Location"
                value={formData.location}
                onChange={handleInputChange}
              />
              <select
                name="projectSite"
                value={formData.projectSite}
                onChange={handleInputChange}
              >
                <option value="">Select project site</option>
                <option value="on-site">On Site</option>
                <option value="off-site">Off Site</option>
              </select>
              <select
                name="natureEmp"
                value={formData.natureEmp}
                onChange={handleInputChange}
              >
                <option value="">Select nature of employment</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
              </select>
              <input
  type="text"
  name="teamSize"
  placeholder="Team Size"
  value={formData.teamSize}
  onChange={(e) => {
    const value = e.target.value;
    // Only allow numeric values
    if (/^\d*$/.test(value)) {
      handleInputChange(e);
    }
  }}
/>
              <input
                type="text"
                name="roleInProject"
                placeholder="Role in Project"
                value={formData.roleInProject}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="skill"
                placeholder="Skills Used"
                value={formData.skill}
                onChange={handleInputChange}
              />
            </div>
          </section>
          <div className="modal_button_section">
            <button className="modal_cancel_button" onClick={onClose}>
              Cancel
            </button>
            <button className="modal_submit_button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectEdit;