import React, { useState } from "react";
import "./EmpLogin.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../assets/images/hire_form_logo.png";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import { useLoginMutation } from "../../../slice/authApiSlice";
import { Co2Sharp, TryOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../slice/authSlice";
import { useNavigate } from "react-router-dom";
import EmpRegister from "../../register/employee/EmpRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import MobileEmployeeReg from "../../register/employee/memployee/MobileEmployeeReg";
import Forgot from "../forgot/Forgot";

const EmpLogin = ({ onClose, onSubmit }) => {
  const [login, { isLoading }] = useLoginMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [empModalOpen, setEmpModalOpen] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);



  
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };


  const loginHandler = async () => {
    let payload = {
      email,
      password,
    };
    if(email === ""){
      toast.error("Email is required");
    }else if(!validateEmail(email)){
      toast.error("Invalid email format");
    }else if(password === ""){
      toast.error("Password is required")
    }else{
    try {
      const res = await login(payload).unwrap();
      if (isLoading) {
        console("lo din");
      } else {
        dispatch(setCredentials({ 
          userInfo: res.user, 
          token: res.token 
        }));
        navigate("/user");
      }
    } catch (error) {
      toast.error("Invalid Credentials");
    }
  }
  };
  const handleEmpModalClick = () => {
    setEmpModalOpen(!empModalOpen);
  };
  const handleForgotClick = () => {
    setForgotOpen(!forgotOpen);
  };
  return (
    <div className="emp_login_section">
      {empModalOpen && (
        <EmpRegister
          onClose={handleEmpModalClick}
          onSubmit={handleEmpModalClick}
        />
      )}
           {forgotOpen && (
        <Forgot
          onClose={handleForgotClick}
          onSubmit={handleForgotClick}
        />
      )}
      {/* <Navbar /> */}
      <div className="emp_login_container section_padding">
        <div className="emp_login_left_side">
          <div className="emp_login_heading_section">
            <div className="logo_img_container">
              <img src={logo} alt="logo" />
            </div>
            <div className="emp_login_heading_container">
              <h2>Find the right candidate for your needs.</h2>
            </div>
          </div>
          <div className="pnt_container">
            <p>Privacy Policy</p>
            <p>Terms & Conditions</p>
          </div>
        </div>
        <div className="emp_login_right_side">
          <div className="close_button_container">
            <button>
              <FontAwesomeIcon
                icon={faXmark}
                className="cross_icon"
                onClick={() => onClose()}
              />
            </button>
          </div>
          <div className="rightside_heading_and_form_container">
            <h2>Welcome, glad to see you!</h2>
            <div className="emp_login_right_side_form_container">
              <form className="emp_login_right_side_form">
                <div className="emp_login_right_side_form_input_container">
                  <label>Employeer Email id</label>
                  <input
                    type="email"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="emp_login_right_side_form_input_container">
                  <label>Passowrd</label>
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="f_password_section">
                  <a onClick={handleForgotClick}>Forgot Password?</a>
                </div>
              </form>
            </div>
            <div className="cand_login_button_container">
              <button onClick={loginHandler}>Login</button>
              <a>Login using OTP</a>
            </div>
          </div>
          <div className="create_account_section">
            <p>Don’t have an account?</p>
            <a onClick={handleEmpModalClick}>
              <button>Create an Account</button>
            </a>
          </div>
        </div>
      </div>
      {/* <Footer className="emp_footer" /> */}
    </div>
  );
};

export default EmpLogin;
