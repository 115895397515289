import React, { useDebugValue, useState } from "react";
import logo from "../../../assets/images/hire_form_logo.png";
import "./EmpRegister.css";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import { useRegisterMutation } from "../../../slice/authApiSlice";
import { setCredentials } from "../../../slice/authSlice";
import { click } from "@testing-library/user-event/dist/click";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Popup from "../../popup/Popup";
import MobileEmployeeReg from "./memployee/MobileEmployeeReg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const EmpRegister = ({ onClose, onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("I am a compnay");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [location, setLocation] = useState("");
  const [contactNumber, setContactNumber] = useState();
  const [cName, setCname] = useState("");
  const [reqRole, setReqRole] = useState("");
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [register, { isLoading }] = useRegisterMutation();
  const [selectedButton, setSelectedButton] = useState(null);

  const registerHandler = async () => {
    if (!firstName) {
      return toast.error("First name is required.");
    }
    if (!lastName) {
      return toast.error("Last name is required.");
    }
    if (!contactNumber || !/^\d{10}$/.test(contactNumber)) {
      return toast.error("Valid 10-digit contact number is required.");
    }
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      return toast.error("Valid email is required.");
    }
    if (!cName) {
      return toast.error("Company name is required.");
    }
    if (!reqRole) {
      return toast.error("Role is required.");
    }
    if (!location) {
      return toast.error("Location is required.");
    }
    if (!password || password.length < 6) {
      return toast.error("Password must be at least 6 characters long.");
    }
    if (password !== confirmPassword) {
      return toast.error("Password is mismatched");
    }
  
 

 
  
    // Payload to be sent
    let payload = {
      firstName,
      lastName,
      email,
      password,
      status,
      location,
      contactNumber,
      cName,
      reqRole,
    };
  
    try {
      const res = await register(payload).unwrap();
      if (isLoading) {
        // console.log("Loading...");
      } else {
        dispatch(setCredentials({ userInfo: res.user, token: res.token }));
        setModalOpen(true);
      }
    } catch (error) {
      toast.error(error.data?.error || error.data?.message || "Registration failed");
    }
  };
  
  const handleButtonClick = (e) => {
    e.preventDefault();
    setSelectedButton(e.target.value);
    setStatus(e.target.value);
    // console.log(e.target.value);
  };

  const handleModalClick = () => {
    setModalOpen(false);
  };
  return (
    <div className="register_section ">
      {modalOpen && (
        <Popup onClose={handleModalClick} onSubmit={handleModalClick} />
      )}
      <div className="register_container ">
        <div className="register_left_side">
          <div className="reg_logo_section">
            <div>
              <img src={logo} alt="logo" />
            </div>
            <h2>Find the right candidate for your needs.</h2>
          </div>
          <div className="reg_pnt_section">
            <p>Privacy Policy</p>
            <p>Terms & Conditions</p>
          </div>
        </div>
        <div className="register_right_side">
          <div className="close_button_container">
            <button>
              <FontAwesomeIcon
                icon={faXmark}
                className="cross_icon"
                onClick={() => onClose()}
              />
            </button>
          </div>
          <div className="register_right_side_heading">
            <h1>Create your recruiter account</h1>
            <p>Join our growing community of recruiters!</p>
          </div>
          <div className="register_right_side_form_container">
            <form>
              <div className="choose_type">
                <label>Choose your company type</label>
                <div className="form_field ">
                  <button
                    type="text"
                    className={
                      selectedButton === "I am a compnay"
                        ? "selectedd"
                        : "notselected"
                    }
                    value="I am a compnay"
                    onClick={handleButtonClick}
                    placeholder="I am a compnay"
                  >
                    I am a compnay
                  </button>

                  <button
                    type="text"
                    className={
                      selectedButton === "I am a consultant"
                        ? "selectedd"
                        : "notselected"
                    }
                    value="I am a consultant"
                    onClick={handleButtonClick}
                    placeholder="I am a consultant"
                  >
                    I am a consultant
                  </button>
                </div>
              </div>
              <div className="form_field">
                <div>
                  <label>First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form_field">
                <div>
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                </div>
                <div>
                  <label>Email Id</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="form_field">
                <div>
                  <label>Organisation Name</label>
                  <input
                    type="text"
                    value={cName}
                    onChange={(e) => setCname(e.target.value)}
                  />
                </div>
                <div>
                  <label>Job Role</label>
                  <input
                    type="text"
                    value={reqRole}
                    onChange={(e) => setReqRole(e.target.value)}
                  />
                </div>
              </div>
              <div className="form_field">
                <div>
                  <label>Location</label>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
              </div>
              <div className="form_field">
                <div>
                  <label>Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <label>Confirm Password</label>
                  <input type="password"  value={confirmPassword}   onChange={(e) => setConfirmPassword(e.target.value)}/>
                </div>
              </div>
            </form>
          </div>
          <button className="reg_submit_button" onClick={registerHandler}>
            Create Account
          </button>
        </div>
      </div>
      {/* <div className="me_reg_section">
        <MobileEmployeeReg onClose={() => onClose()} />
      </div> */}
    </div>
  );
};

export default EmpRegister;
