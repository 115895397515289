import React, { useState } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useComapnydotAddMutation } from "../../slice/authApiSlice";
import { setCredentials } from "../../slice/authSlice";
import { FaImage } from "react-icons/fa";

const Company = ({ onClose, onSubmit }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [comapnydotAdd, { isLoading }] = useComapnydotAddMutation();
  const [backgroundPicture, setBackgroundpicture] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const [companyData, setCompanyData] = useState({
    companyLogo: null,  // Initialize as null
    cName: "",
    ceoName: "",
    industry: "",
    ownerShip: "",
    role: "",
    description: "",
    address: "",
    numberOfOffice: null,
    numberOfEmployees: null,
    establishedIn: null,
    websiteUrl: "",
    fax: "",
    phone: "",
    officeFacebook: "",
    officeTwitter: "",
    officeLinkedin: "",
    country: "",
    state: "",
    city: ""
  });

  const handleTokenimg = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackgroundpicture(file);  // Set file for the companyLogo
      setSelectedFile(URL.createObjectURL(file));  // For preview
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("companyLogo", backgroundPicture);
      Object.keys(companyData).forEach((key) => {
        formData.append(key, companyData[key]);
      });

      let res = await comapnydotAdd(formData).unwrap();
      dispatch(setCredentials({ userInfo: res.user, token: token }));
      onSubmit();
    } catch (error) {
      console.error("Error creating company:", error);
      onClose();
    }
  };

  return (
    <div className="modal_container">
      <div className="modal_content">
        <div className="modal_x_button">
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="modal_text_content">
          <div className="resume_headline_text">
            <h1>Create Company</h1>
            <p>Fill all the fields</p>
          </div>
          <section className="edu-inner">
            <div className="input_group">
              <div className="gpthForm">
                <div className="gpthFormUp">
                  <p>Company Logo</p>
                </div>
                <div>
                  <input
                    type="file"
                    name="companyLogo"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleTokenimg}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="fileInput">
                    <div className="hide_file1">
                      {selectedFile ? (
                        <img
                          src={selectedFile}
                          alt="Selected File"
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                      ) : (
                        <FaImage className="biImg2" />
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <input
                type="text"
                name="cName"
                value={companyData.cName}
                onChange={handleInputChange}
                placeholder="Enter company name"
              />
              <input
                type="text"
                name="ceoName"
                value={companyData.ceoName}
                onChange={handleInputChange}
                placeholder="Enter CEO name"
              />
              <input
                type="text"
                name="industry"
                value={companyData.industry}
                onChange={handleInputChange}
                placeholder="Enter industry"
              />
              <input
                type="text"
                name="ownerShip"
                value={companyData.ownerShip}
                onChange={handleInputChange}
                placeholder="Enter ownership type"
              />
              <input
                type="text"
                name="role"
                value={companyData.role}
                onChange={handleInputChange}
                placeholder="Enter company role"
              />
              <textarea
                name="description"
                value={companyData.description}
                onChange={handleInputChange}
                placeholder="Enter company description"
              />
              <input
                type="text"
                name="address"
                value={companyData.address}
                onChange={handleInputChange}
                placeholder="Enter address"
              />
              <input
                type="text"
                name="numberOfOffice"
                value={companyData.numberOfOffice}
               
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleInputChange(e)
                  }
              }}
                placeholder="Enter number of offices"
              />
              <input
                type="text"
                name="numberOfEmployees"
                value={companyData.numberOfEmployees}
                  onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleInputChange(e)
                  }
              }}
                placeholder="Enter number of employees"
              />
              <input
                type="text"
                name="establishedIn"
                value={companyData.establishedIn}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleInputChange(e)
                  }
              }}
                placeholder="Enter establishment year"
              />
              <input
                type="text"
                name="websiteUrl"
                value={companyData.websiteUrl}
                onChange={handleInputChange}
                placeholder="Enter website URL"
              />
              <input
                type="text"
                name="fax"
                value={companyData.fax}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleInputChange(e)
                  }
              }}
                placeholder="Enter fax"
              />
              <input
                type="text"
                name="phone"
                value={companyData.phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleInputChange(e)
                  }
              }}
                placeholder="Enter phone number"
              />
              <input
                type="text"
                name="officeFacebook"
                value={companyData.officeFacebook}
                onChange={handleInputChange}
                placeholder="Enter office Facebook URL"
              />
              <input
                type="text"
                name="officeTwitter"
                value={companyData.officeTwitter}
                onChange={handleInputChange}
                placeholder="Enter office Twitter URL"
              />
              <input
                type="text"
                name="officeLinkedin"
                value={companyData.officeLinkedin}
                onChange={handleInputChange}
                placeholder="Enter office LinkedIn URL"
              />
              <input
                type="text"
                name="country"
                value={companyData.country}
                onChange={handleInputChange}
                placeholder="Enter country"
              />
              <input
                type="text"
                name="state"
                value={companyData.state}
                onChange={handleInputChange}
                placeholder="Enter state"
              />
              <input
                type="text"
                name="city"
                value={companyData.city}
                onChange={handleInputChange}
                placeholder="Enter city"
              />
            </div>
          </section>
          <div className="modal_button_section">
            <button className="modal_cancel_button" onClick={onClose}>
              Cancel
            </button>
            <button className="modal_submit_button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
