import React from 'react';
import { useLocation } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./apply.css";
import cImg from "../../assets/company.png"
import { generatePublicUrl } from '../../config/image';

function DetailsJopApp() {
    const location = useLocation();
    const { data } = location.state;

    // console.log(data);

    const statusMap = {
        applied: 20,
        'under review': 40,
        shortlisted: 60,
        rejected: 80,
        hired: 100,
    };

    const progress = statusMap[data?.status] || 0; // Default to 0 if status not found

    return (
        <>
         <div className="job-gg">
        
           <h2>Application status</h2>
           <Box className sx={{ width: '70%' }}>
                        <Typography variant="body1" gutterBottom style={{color: "white"}}>
                            Progress ({data?.status} - {progress}%)
                        </Typography>
                        <LinearProgress
                        sx={{
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: 'white', // White color for the progress bar
                            }
                        }} 
                         variant="determinate" value={progress} />
                    </Box>
      </div>
        <section className='AppliedJobs'>
            <div className='Applied-inner'>
                <div className='applied-img'>
                   
                    {data?.company?.companyLogo === "" ?  <img src={cImg} /> : <img src={generatePublicUrl(data?.company?.companyLogo)} />}
                </div>
                <div className='applied-desc'>
                    <h1>{data?.title}</h1>
                    <h2>{data?.jobRole} ({data?.company?.companyName})</h2>
                    {/* <h5></h5> */}
                    <h3>Description:</h3>
                    <p>{data?.description}</p>
                    
                    
                    {/* <p>{data?.status}</p> */}

                
                    {/* <h6>* We will be notified you when status change</h6> */}
                </div>
            </div>
        </section>
        </>
    );
}

export default DetailsJopApp;
