import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useSkillDetQuery, useSkillAddMutation, useSkillRemoveMutation } from "../../slice/authApiSlice";
import { setCredentials } from "../../slice/authSlice";
import "./ModalGeneric.css";
import { useSelector, useDispatch } from "react-redux";

const Skils = ({ onClose, onSubmit }) => {
  const { data, isLoading: isLoadingCategories, error } = useSkillDetQuery();
  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [addSkill, { isLoading: isAddingSkill }] = useSkillAddMutation();
  const [skillRemove, { isLoading: isRemoveSkill }] = useSkillRemoveMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedCategories, setDisplayedCategories] = useState([]);

  useEffect(() => {
    if (data && data.categories) {
      if (searchTerm === "") {
        const initialCategory = data.categories.find(
          (category) => category.name.toLowerCase() === "search technology"
        );
        setDisplayedCategories(initialCategory ? [initialCategory] : []);
      } else {
        const filteredCategories = data.categories.filter((category) =>
          category.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setDisplayedCategories(filteredCategories);
      }
    }
  }, [searchTerm, data]);

  if (isLoadingCategories) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = async (e, skill) => {
    e.stopPropagation(); // Prevent event from bubbling up
    e.preventDefault();  // Prevent default anchor behavior

    const payload = { skill };
    try {
      const res = await addSkill(payload).unwrap();
      dispatch(setCredentials({ userInfo: res, token: token }));
    } catch (error) {
      // console.log(error);
    }
  };

  const handleRemove = async (e, skill) => {
    e.stopPropagation(); // Prevent event from bubbling up
    e.preventDefault();  // Prevent default anchor behavior

    const payload = { skill };
    try {
      const res = await skillRemove(payload).unwrap();
      dispatch(setCredentials({ userInfo: res, token: token }));
    } catch (error) {
      // console.log(error);
    }
  };

  const isSkillPresent = (skillName) => {
    return user.keySkills.some((skill) => skill.skill === skillName);
  };

  return (
    <div className="skills_container modal_container" onClick={(e) => e.stopPropagation()}>
      <div className="modal_content">
        <div className="modal_x_button">
          <button onClick={() => onClose()}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="modal_text_content">
          <div className="resume_headline_text">
            <h1>Key skills</h1>
            <p>
              Add skills that best define your expertise, for e.g, Direct
              Marketing, Oracle, Java, etc. (Minimum 1)
            </p>
          </div>
          <div className="skills-input-container1">
            <input
              type="text"
              placeholder="Search technology..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="categories-box">
              {displayedCategories.length > 0 ? (
                displayedCategories.map((category) => (
                  <div key={category._id} className="search-flex">
                    <p>{category.name}</p>
                    {isSkillPresent(category.name) ? (
                      <button
                        className="modal_submit_button"
                        onClick={(e) => handleRemove(e, category.name)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    ) : (
                      <button
                        onClick={(e) => handleSubmit(e, category.name)}
                      >
                        <FontAwesomeIcon icon={faAdd} />
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <div>No categories found.</div>
              )}
            </div>
          </div>
          <div className="modal_button_section">
            <button className="modal_cancel_button" onClick={() => onClose()}>
              Cancel
            </button>
            <button className="modal_submit_button" onClick={() => onSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skils;
