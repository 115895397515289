import React, { useState } from 'react';
import "./UserNavbar.css";
import { useSelector } from 'react-redux';
import { useFindSkilledJobsMutation, useSearchJobMutation, useVisitUserMutation } from '../../../slice/authApiSlice';
import { useNavigate } from 'react-router-dom';

function SearchComponent({closeModal}) {
    const user = useSelector((state) => state.auth.userInfo);
    const [skills, setSkills] = useState([]);
    const [experience, setExperience] = useState("");
    const [location, setLocation] = useState("");
    const [jobResults, setJobResults] = useState([]);
    const [jobResults1, setJobResults1] = useState([]);
    const [searchJob, { isLoading }] = useSearchJobMutation();
    const [findSkilledJobs, { isLoading: findLoading }] = useFindSkilledJobsMutation();
    const navigate = useNavigate();
    const [visitUser, { isLoading: uservisitLoading }] = useVisitUserMutation();

    const handleSearch = async () => {
        try {
          const requestBody = {
            skills,
            experience,
            location,
          };
    
         if(user?.role === "user"){
          const response = await findSkilledJobs(requestBody).unwrap();
          
          setJobResults1(response);
         }else{
          const response = await searchJob(requestBody).unwrap();
          
          setJobResults(response);
         }
        } catch (error) {
          console.error("Error searching jobs:", error);
          alert("Failed to search jobs.");
        }
      };

      const handleSkillsChange = (e) => {
        const value = e.target.value;
        setSkills(value.split(',').map(skill => skill.trim()));
      };

      const visitUserData = async(data) => {
        navigate(`/candidate/${data._id}`, { state: { data } });
        const userId = data._id;
        const res =  await visitUser({userId});
        // console.log(res);
        closeModal();
        
    };
    const visitJobData = (data) => {
      navigate(`/job/${data._id}/details`, { state: { data } });
      closeModal();
    }
     
  return (
    <div className='box-notification'>
        <div className='box-noti-cont-header'>
            <div className='search_mob_container_inner'>
            <input
            type="search"
            placeholder="Enter skills"
            value={skills.join(', ')}
            onChange={handleSkillsChange}
          />
            <input
            placeholder="Experience"
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          />

            <input
              type="text"
              placeholder="Enter location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div> <button className='mob-search-btn' onClick={handleSearch}>Search</button></div>
        
     {jobResults1 && (
           <div className='box-noti-innerContainer'>
           {jobResults1?.slice().reverse().map(noti => (
                 <div className='notification-card'>
                 <div className='notification-card-inner-left'>
                    <h5>{noti?.title}</h5>
                 </div>
                 <div className='notification-card-inner-right'>
                     <h5 onClick={() => visitJobData(noti)}>view</h5>
                 </div>
            </div>
           ))}
           
      </div>
     )}

     {jobResults && (
           <div className='box-noti-innerContainer'>
           {jobResults?.slice().reverse().map(noti => (
                 <div className='notification-card'>
                 <div className='notification-card-inner-left'>
                    <h5>{noti?.firstName}  {noti.lastName}</h5>
                 </div>
                 <div className='notification-card-inner-right'>
                     <h5 onClick={() => visitUserData(noti)}>view</h5>
                 </div>
            </div>
           ))}
           
      </div>
     )}
    </div>
  )
}

export default SearchComponent