import React, { useState, useEffect, useRef } from "react";
import "./UserHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { useFindSkilledJobsMutation, useSearchJobMutation, useVisitUserMutation } from "../../../slice/authApiSlice"; 
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import cImg from "../../../assets/company.png"
import { generatePublicUrl } from "../../../constants";

const UserHeader = () => {
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSuggestionsUser, setShowSuggestionsUser] = useState(false);
  const [jobResults, setJobResults] = useState([]);
  const [jobResults1, setJobResults1] = useState([]);
  const suggestionsRef = useRef(null); // Reference to the suggestions box
  const suggestionsUserRef = useRef(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.userInfo);
  const [visitUser, { isLoading: uservisitLoading }] = useVisitUserMutation();

  // Mutation hook for searching jobs
  const [searchJob, { isLoading }] = useSearchJobMutation();
  const [findSkilledJobs, { isLoading: findLoading }] = useFindSkilledJobsMutation();

  // Function to call API and get filtered jobs based on skills, experience, and location
  const handleSearch = async () => {
    try {
      const requestBody = {
        skills,
        experience,
        location,
      };

     if(user?.role === "user"){
      const response = await findSkilledJobs(requestBody).unwrap();
      setShowSuggestionsUser(true);
      // console.log(response);
      
      setJobResults1(response);
     }else{
      const response = await searchJob(requestBody).unwrap();
      setShowSuggestions(true);
      // console.log(response);
      
      setJobResults(response);
     }
    } catch (error) {
      console.error("Error searching jobs:", error);
      alert("Failed to search jobs.");
    }
  };

  const handleSkillsChange = (e) => {
    const value = e.target.value;
    setSkills(value.split(',').map(skill => skill.trim()));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [suggestionsRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsUserRef.current && !suggestionsUserRef.current.contains(event.target)) {
        setShowSuggestionsUser(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [suggestionsUserRef]);

  const visitUserData = async(data) => {
    navigate(`/candidate/${data._id}`, { state: { data } });
    const userId = data._id;
    const res =  await visitUser({userId});
    // console.log(res);
    
};
const visitJobData = (data) => {
  navigate(`/job/${data._id}/details`, { state: { data } });
}

  return (
    <div className="user_header_container">
      
      <div className="sarch_section1">
        <div className="header_search_container">
          <FontAwesomeIcon className="search_icon" icon={faMagnifyingGlass} />
          <input
            className="header_search"
            type="search"
            placeholder="Enter skills (comma separated)"
            value={skills.join(', ')} // Display skills as a comma-separated string
            onChange={handleSkillsChange}
          />
        </div>

        <div className="header_select_container">
          <FontAwesomeIcon className="experience_icon" icon={faBriefcase} />
          <input
            placeholder="Experience"
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          />
        </div>

        <div className="header_input_container">
          <div className="heder_input">
            <input
              type="text"
              placeholder="Enter location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button className="header_search_button" onClick={handleSearch} disabled={isLoading}>
            {isLoading ? "Searching..." : "Search"}
          </button>
        </div>
      </div>

      {/* Suggestions Box if applicable */}
      {showSuggestions && (
        <>{jobResults.length ===0 ? <div className="suggestions_box1" ref={suggestionsRef}> <h1>No result found</h1></div> :    <div className="suggestions_box" ref={suggestionsRef}>
        {
         jobResults.map(result=> {
          return(
           <div className="pr-section">
              <div className="pr-section-inner">
               <div className="pr-img-fg">
              

               {result?.profilePictures.length === 0 ?  <p>{result.firstName[0] + result.lastName[0]}</p> : <img src={generatePublicUrl(result.profilePictures[0].img)} />}
               
               </div>
               <div className="pr-desc-fr">
                 <h2>{result?.firstName}  {result.lastName}</h2>
                 <p>{result?.email}</p>
                 <p>{result?.contactNumber}</p>
               </div>
              </div>
              <div className="pr-fg-btn">
               <button onClick={() => visitUserData(result)}>Visit user</button>
              </div>
           </div>
          )
         })
        }
     </div>}</>
      )}



      {/* user box */}

      {showSuggestionsUser && (
        <div className="suggestions_box" ref={suggestionsUserRef}>
           {
            jobResults1.map(result=> {
             return(
              <div className="pr-section">
                 <div className="pr-section-inner">
                  <div className="pr-img-fg">
                  <img src={generatePublicUrl(result.companyDetails.companyLogo)} />
                  </div>
                  <div className="pr-desc-fr">
                    <h2>{result?.title}</h2>
                    <p>{result.companyDetails.companyName}</p>
                    <p>{result?.numPosition}</p>
                  </div>
                 </div>
                 <div className="pr-fg-btn">
                  <button onClick={() => visitJobData(result)}>Visit Job Details page</button>
                 </div>
              </div>
             )
            })
           }
        </div>
      )}
    </div>
  );
};

export default UserHeader;
