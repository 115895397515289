import React, { useEffect, useState } from 'react'; 
import { useLocation } from 'react-router-dom';
import "./save.css";
import cImg from "../../assets/company.png";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from 'react-countdown';
import { setJobData, updateJobApplicant } from '../../slice/jobSlice';
import { useApplyJobMutation, useJobDetByIdQuery, useJobDetByIdUserQuery } from '../../slice/authApiSlice';
import { toast } from 'react-toastify';
import { setJob } from '../../slice/updateJobSlice';
import { generatePublicUrl } from '../../config/image';

function DetailsJob() { 
    const location = useLocation();
    const { data } = location.state;
    const [applyJob, { isLoading: jobLoading }] = useApplyJobMutation();
    const updateJob = useSelector(state => state.updateJob.updateJob);
    const [newJob, setNewJob] = useState(data);
    const { data:jobDetByIdUser, isLoading: isLoadingjobDet, error: jobError } = useJobDetByIdUserQuery(data._id, {
        skip: !data?._id
    });
    const user = useSelector(state => state.auth.userInfo);
    const dispatch = useDispatch();
 // Default to 0 if status not found
    // console.log(data, "stattttttttttttttttttttttttttteeeeeeeeeeeeeeeeeeeeeeee");
    // console.log(newJob);

    // Check if the user has already applied and retrieve the corresponding status
 


    const statusMap = {
        notaplied: 0,
        applied: 20,
        'under review': 40,
        shortlisted: 60,
        rejected: 80,
        hired: 100,
    };

    

    var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span className="newBiddingTimer1">Job Expired</span>
        } else {
          return <span className="newBiddingTimer1">{days}d  {hours}h {minutes}m {seconds}s left</span>;
        }
      };



      useEffect(() => {
        // Ensure jobDet is not empty or undefined
        if (jobDetByIdUser) {
          dispatch(setJob(jobDetByIdUser));
          // console.log(jobDetByIdUser);
        //   setNewJob(jobDetByIdUser)
          
        }
      }, [dispatch, jobDetByIdUser, data?._id]); 

      // console.log(newJob, updateJob);
      

      const applicant = newJob?.applicant?.find(applicant => applicant.userId === user?._id);
      // console.log(applicant);
      
      const isAlreadyApplied = !!applicant;
      // console.log(isAlreadyApplied);
      

      const progress = statusMap[applicant?.status] || 0;

      const handleApplyBtn = async () => {
        // console.log(user._id);
        const id = data._id;
        try {
            const res = await applyJob(id).unwrap();
            // console.log(res);
            
            dispatch(updateJobApplicant({
                jobId: id,
                applicant: res.updatedJob.applicant
            }));
            dispatch(setJobData(res.job))
            dispatch(setJob(res.updatedJob))
            setNewJob(res.updatedJob)
            // isAlreadyApplied = !!applicant
            // console.log(updateJob, newJob, jobDetByIdUser,"after update");
            toast.success("Applied success fully")
            
        } catch (error) {
            // console.log(error);
            alert(error);
        }
    };


    return (
        <section className='sc-det-area-upper'>
       <div className="job-gg">
        
        <h2>Application status</h2>
        <Box className sx={{ width: '70%' }}>
                     <Typography variant="body1" gutterBottom style={{color: "white"}}>
                     {progress === 0 ? 'Progress (Not Applied - 0%)' : `Progress (${applicant.status} - ${progress}%)`}
                     </Typography>
                     <LinearProgress
                     sx={{
                         '& .MuiLinearProgress-bar': {
                             backgroundColor: 'white', // White color for the progress bar
                         }
                     }} 
                      variant="determinate" value={progress} />
                 </Box>
   </div>

        <section className='AppliedJobs'>
            <div className='Applied-inner'>
                <div className='applied-img'>
                   {newJob?.companyDetails?.companyLogo === "" ? <img src={cImg} alt="Company logo" /> :  <img src={generatePublicUrl(newJob?.companyDetails?.companyLogo)} alt="Company logo" /> }
                   
                </div>
               <section className='mob-det-section-data'>
               <div className='hide-in-desk-strong'>
                <div className='hide-in-desk-strong-img'>{newJob?.companyDetails?.companyLogo === "" ? <img src={cImg} alt="Company logo" /> :  <img src={generatePublicUrl(newJob?.companyDetails?.companyLogo)} alt="Company logo" /> }</div>
                <div>Job details in below</div>
                
                </div>
               </section>
                <div className='applied-desc'>
                    <h1>{data?.title} ({data?.companyDetails?.companyName})</h1>
                    <h2>Job location: {data?.location} </h2>
                    <h3>Number Of Openings: {data?.numPosition}</h3>
                    <h3>Job Expired in:  <Countdown date={new Date(data.endDate)}
                                      autoStart={true}
                                      onStart={() => new Date(data.endDate)}
                                      onStop={() => new Date(data.endDate)}
                                      renderer={renderer}
                                    >
                                    </Countdown></h3>
                    <h3>Description:</h3>
                    <p>{data?.description}</p>

                    <button
  className={
    newJob?.applicant?.some(applicant => applicant.userId === user?._id) ? 'applied-btn' : 'apply-btn'
  }
  onClick={handleApplyBtn}
  disabled={newJob?.applicant?.some(applicant => applicant.userId === user?._id)}
>
  {newJob?.applicant?.some(applicant => applicant.userId === user?._id) ? 'Already applied' : 'Apply now'}
</button>

                </div>
            </div>
        </section>
        </section>
    );
}

export default DetailsJob;
