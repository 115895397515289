import React from "react";
import "./Forgot.css";
import logo from "../../../assets/images/hire_form_logo.png";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import { useCandidateLoginMutation, useForgotPasswordMutation } from "../../../slice/authApiSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../slice/authSlice";
import { useNavigate } from "react-router-dom";
import CandRegister from "../../register/candidate/CandRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import MobileCandidateReg from "../../register/candidate/mcandidate/MobileCandidateReg";

const Forgot = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [candModalOpen, setCandModalOpen] = useState(false);




  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };


  const loginHandler = async () => {
    const payload = {
      email
    };
    if(email === ""){
      toast.error("Email is required");
    }else if(!validateEmail(email)){
      toast.error("Invalid email format");
    }else{
      try {
        const res = await forgotPassword(payload).unwrap();
        navigate("/");
        onSubmit();
        toast.success("It seems like you forgot your password for riso hire. If this is true, check your mail")
      } catch (error) {
        toast.error(error?.data?.message || error);
        onClose();
      }
    }
 
  };

  const handleCandModalClick = () => {
    setCandModalOpen(!candModalOpen);
  };

  return (
    <div className="cand_login_section">
      {candModalOpen && (
        <CandRegister
          onClose={handleCandModalClick}
          onSubmit={handleCandModalClick}
        />
      )}
      {/* <Navbar /> */}
      <div className="cand_login_container ">
        <div className="cand_login_left_side">
          <div className="cand_login_heading_section">
            <div className="logo_img_container">
              <img src={logo} alt="logo" />
            </div>
            <div className="cand_login_heading_container">
              <h2>Explore 5 lakh+ career opportunities.</h2>
            </div>
          </div>
          <div className="pnt_container">
            <p>Privacy Policy</p>
            <p>Terms & Conditions</p>
          </div>
        </div>
        <div className="cand_login_right_side">
          <div className="close_button_container">
            <button>
              <FontAwesomeIcon
                icon={faXmark}
                className="cross_icon"
                onClick={() => onClose()}
              />
            </button>
          </div>
          <div className="rightside_heading_and_form_container">
            <h2>Forgot, your password!</h2>
            <div className="cand_login_right_side_form_container">
              <form className="cand_login_right_side_form">
                <div className="cand_login_right_side_form_input_container">
                  <label>Enter your email id</label>
                  <input
                    type="email"
                    placeholder="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="cand_login_button_container">
              <button onClick={loginHandler}>Forgot</button>
              <a>Login using OTP</a>
            </div>
          </div>
          <div className="create_account_section">
            <p>Don’t have an account?</p>
            <a onClick={handleCandModalClick}>
              <button>Create an Account</button>
            </a>
          </div>
        </div>
      </div>
      {/* <Footer className="footer" /> */}
    </div>
  );
};

export default Forgot;