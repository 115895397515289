// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slice/apiSlice";
import authSliceReducer from "./slice/authSlice";
import instituteSliceReducer from "./slice/instituteSlice";
import jobSliceReducer from "./slice/jobSlice";
import updateJobSliceReducer from "./slice/updateJobSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    institute: instituteSliceReducer,
    job: jobSliceReducer,
    updateJob: updateJobSliceReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

export default store;
