import React, { useEffect } from "react";

import TopCompanies from "../containers/topCompanies/TopCompanies";
import FeaturedCompany from "../containers/featuredComapny/FeaturedCompany";
import PopularSearchCategories from "../containers/popular_search/PopularSearchCategories";
import Hero from "../containers/hero/Hero";
import Testimonials from "../containers/testimonials/Testimonials";
import Footer from "../containers/footer/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const user = useSelector((state) => state.auth.userInfo);
  const navigate = useNavigate();
  useEffect(() => {
    user?.verified && navigate("/user");
  }, [user?.verified]);
  return (
    <div>
      <Hero />
      <PopularSearchCategories />
      <TopCompanies />
      <FeaturedCompany />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Home;
