import React, { useState } from "react";
import "./Private.css";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import UserNavbar from "../../containers/userHome/userNav/UserNavbar";
import Footer from "../../containers/footer/Footer";

const Private = () => {
  const user = useSelector((state) => state.auth.userInfo);
  const [closeProfileDropdown, setCloseProfileDropdown] = useState(null);

  // const handleCloseProfileDropdown = () => {
  //   setCloseProfileDropdown(closeProfileDropdown);
  // };

  return user && user.verified ? (
    <div
      className="privateContainer"
      onClick={() => setCloseProfileDropdown(false)}
    >
      <div className="background"></div>
      <UserNavbar props={closeProfileDropdown} />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  ) : (
    <Navigate to="/" replace />
  );
};

export default Private;
