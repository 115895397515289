// src/slice/jobSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    updateJob: {}, // Assuming the job is an object, not an array
};

const updateJobSlice = createSlice({
    name: 'updateJob',
    initialState,
    reducers: {
        setJob: (state, action) => {
            // Replace the entire job object with the new job data from action.payload
            state.updateJob = action.payload;
            localStorage.setItem("updateJob", JSON.stringify(state.updateJob));
        },
    },
});

export const { setJob } = updateJobSlice.actions;

export default updateJobSlice.reducer;
