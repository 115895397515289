import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./job.css";
import UserHeader from '../userHome/userHeader/UserHeader';
import { useApplyJobMutation, useSaveJobMutation, useUnsaveJobMutation } from '../../slice/authApiSlice';
import { setJobData, updateJobApplicant } from '../../slice/jobSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faBookmark, faSave } from "@fortawesome/free-solid-svg-icons";
import { FaBookmark, FaRegBookmark } from 'react-icons/fa6';
import { setCredentials } from '../../slice/authSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import cImg from "../../assets/company.png"
import { generatePublicUrl } from '../../config/image';

function Jobs() {
    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const jobs = useSelector((state) => state.job.jobData);
    const [applyJob, { isLoading: jobLoading }] = useApplyJobMutation();
    const [saveJob, { isLoading: saveLoading }] = useSaveJobMutation();
    const [unsaveJob, { isLoading: unsaveLoading }] = useUnsaveJobMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const jobsPerPage = 8;

    // Get current jobs to display
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

    // Function to handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(jobs.length / jobsPerPage);


    useEffect(() => {
        // console.log(jobs, "Jobs after update");
      }, [jobs]);

    const handleApplyBtn = async (details) => {
        // console.log(user._id, details);
        const id = details._id;
        try {
            const res = await applyJob(id).unwrap();
            // console.log(res);
            
            dispatch(updateJobApplicant({
                jobId: id,
                applicant: res.updatedJob.applicant
            }));
            dispatch(setJobData(res.job))
            // console.log(jobs, res.job,"after update");
            toast.success("Applied success fully")
            
        } catch (error) {
            // console.log(error);
            alert(error);
        }
    };

    const handleSaveJob = async (id) => {
        let payload = {
            jobId: id
        };
        try {
            const res = await saveJob(payload).unwrap();
            dispatch(setCredentials({ userInfo: res.savedJobs, token: token }));
            // console.log(res);
        } catch (error) {
            // console.log(error);
        }
    };

    const handleUnSaveJob = async (id) => {
        let payload = {
            jobId: id
        }
        try {
            const res = await unsaveJob(payload).unwrap();
            dispatch(setCredentials({ 
                userInfo: { ...user, savedJobs: res.savedJobs }, 
                token: token 
            }));
            // console.log(res);
        } catch (error) {
            
        }
    }

    const visitJobData = (data) => {
        navigate(`/job/${data._id}/details`, { state: { data } });
    }

    return (
        <div className='jobpage'>
            <div>
                <UserHeader />
                <h2 className='latest-header-job-design'>Latest jobs ({jobs?.length})</h2>
            </div>
            <section className='job-outer'>
                <div className='job-inner-page'>
                    {currentJobs?.map(jb => (
                        <div className='job-card' key={jb._id}>
                            {/* Check if the job is already saved */}
                            {user.savedJobs.includes(jb._id) ? (
                                <FaBookmark className='bookMarkIc' onClick={() => handleUnSaveJob(jb._id)} />
                            ) : (
                                <FaRegBookmark className='bookMarkIc' onClick={() => handleSaveJob(jb._id)}>Save</FaRegBookmark> // If not saved, show 'Save' button
                            )}
                            <div className='jd-outer'>
                                <div className='job-img-det'>
                                {jb?.companyDetails.companyLogo === "" ? <img src={cImg}/> : <img src={generatePublicUrl(jb?.companyDetails.companyLogo)}/> }
                                </div>
                                <div className='jd-outer-det-new'>
                                    <h2>{jb.title.length > 20 ? `${jb.title.slice(0, 20)}...` : jb.title}</h2>
                                    <p>{jb?.description.length > 52 ? `${jb?.description.slice(0, 52)}...` : jb?.description }</p>
                                    <div className='jdd-btn'>
                                        <button onClick={() => visitJobData(jb)}>See details</button>
                                        {jb?.applicant?.some(applicant => applicant.userId === user._id) ? (
                                            <button disabled>Applied</button>
                                        ) : (
                                            <button onClick={() => handleApplyBtn(jb)} disabled={jobLoading}>Apply now</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                
            </section>
            <div className="pagination">
                {[...Array(totalPages).keys()].map(pageNumber => (
                    <button
                        key={pageNumber + 1}
                        onClick={() => paginate(pageNumber + 1)}
                        className={currentPage === pageNumber + 1 ? 'active' : ''}
                    >
                        {pageNumber + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default Jobs;
