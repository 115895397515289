import React, { useState, useEffect } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTypeDetQuery, useEducationUpdateMutation, useInstituteDetQuery, useInstituteUpdateMutation, useCourseDetQuery, useSpecialDetQuery, useCourseTypeDetQuery, useEducationEditMutation } from "../../slice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../slice/authSlice";
import { setInstituteData } from "../../slice/instituteSlice";

const Edu = ({ onClose, onSubmit, initialData }) => {
    const { data:typeData, isLoading: isLoadingCategories, error: typeError } = useTypeDetQuery();
    const { data:instituteData, isLoading: isLoadingInstitute, error: institteError } = useInstituteDetQuery();
    const { data:cTypeData, isLoading: isLoadingCtype, error: ctypeError } = useCourseTypeDetQuery();
    const { data:courseData, isLoading: isLoadingCourse, error: courseError } = useCourseDetQuery();
    const instituteDataNew = useSelector((state) => state.institute.instituteData);

    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();

    const [cate, setCate] = useState(initialData?.category);
    const [instituteName, setInstituteName] = useState(initialData?.instituteName || "");
    const [instituteId, setInstituteId] = useState(initialData?.instituteName || "");
    const [passOut, setPassOut] = useState(initialData?.passOut);
    const [startYear, setStartYear] = useState(initialData?.startYear);
    const [filteredItems, setFilteredItems] = useState(instituteDataNew);
    const [course, setCourse] = useState(initialData?.course ? { name: initialData?.course } : null);
    const { data:specialData, isLoading: isLoadingSpecial, error: specialError } = useSpecialDetQuery(course?._id, {
        skip: !course
    });
    const [courseType, setCourseType] = useState(initialData?.courseType);
    const [specialization, setSpecialization] = useState(initialData?.specialization || "");
    const [showOtherModal, setShowOtherModal] = useState(false); // New state for the "Other" modal
    const [educationEdit, { isLoading }] = useEducationEditMutation();
    const [instituteUpdate, { isLoading: updateLoading }] = useInstituteUpdateMutation();


    //country


    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [inName, setInName] = useState("");
    const [selectCourss, setSelectCourse] = useState("");

//

// console.log(initialData);



const config = {
    cUrl: 'https://api.countrystatecity.in/v1/countries',
    ckey: 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA=='
};

useEffect(() => {
    loadCountries();
}, []);




const loadCountries = () => {
    fetch(config.cUrl, {
        headers: { 'X-CSCAPI-KEY': config.ckey }
    })
        .then((response) => response.json())
        .then((data) => {
            setCountries(data);
        })
        .catch((error) => console.error('Error loading countries:', error));
};

const loadStates = (countryCode) => {
    setStates([]);
    setCities([]);
    setSelectedCountry(countryCode);
    // console.log(countryCode);
    

    if (countryCode) {
        fetch(`${config.cUrl}/${countryCode}/states`, {
            headers: { 'X-CSCAPI-KEY': config.ckey }
        })
            .then((response) => response.json())
            .then((data) => {
                setStates(data);
            })
            .catch((error) => console.error('Error loading states:', error));
    }
};

const loadCities = (stateCode) => {
    // console.log(stateCode);
    
    setCities([]);
    setSelectedState(stateCode);

    if (stateCode) {
        fetch(`${config.cUrl}/${selectedCountry}/states/${stateCode}/cities`, {
            headers: { 'X-CSCAPI-KEY': config.ckey }
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                
                setCities(data);
            })
            .catch((error) => console.error('Error loading cities:', error));
    }
};

const selectCities = (city) => {
//    console.log(city);
   
  setSelectedCity(city);

};




    //

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setInstituteName(value);

        const filtered = instituteDataNew.filter(item =>
            item.name.toLowerCase().includes(value)
        );

        setFilteredItems(filtered.length > 0 ? filtered : [{ name: 'Other', value: 'other' }]);
    };

    const handleClick = (value) => {
        if (value.name === 'Other') {
            setShowOtherModal(true); // Open the "Other" modal
        } else {
            setInstituteName(value.name);
            setInstituteId(value.name)
            setFilteredItems([]);
        }
    };

    const handleChange = (event) => {
        setCate(event.target.value);
    };

    const handleSubmit = async () => {

        const payload = {
            category: cate,
            instituteName: instituteId,
            course: course?.name,
            courseType,
            specialization,
            startYear,
            passOut,
            educationId: initialData?._id
        };

        // console.log(payload);
        
        try {
            const res = await educationEdit(payload).unwrap();
            dispatch(setCredentials({ userInfo: res.updatedUser, token: token }));
            onSubmit();
        } catch (error) {
            
        }

        // console.log(payload);
        // Additional logic for submission here...
    };

    const closeOtherModal = () => {
        setShowOtherModal(false);
    };

    const handleAddCollegeData = async() => {
        let payload ={
            name: inName, 
            town: selectedCity, 
            country: selectedCountry, 
            state: selectedState
        }

        try {
            const res = await instituteUpdate(payload).unwrap();
            if(res){
                // console.log("enter");
                
                dispatch(setInstituteData(res.data));
                setShowOtherModal(false)
            }
        } catch (error) {
            // console.log(error);
            
        }
        
        
    }

    const handleCourseChange = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const course = JSON.parse(selectedOption.dataset.course); // Retrieve the entire object
        setCourse(course);
        // console.log(course);
      };


    //   console.log(selectCourss);
      
      const handleSpecializzationChange = (event) => {
      

            setSpecialization(event.target.value);
        
      }

      const handleCourseTYpeChange = (event) =>{
        setCourseType(event.target.value)
      }

      const handleStartYearChange = (event) => {
        setStartYear(event.target.value)
      }
      const handlePassOut = (event) => {
        setPassOut(event.target.value)
      }
    

    //   console.log(cTypeData);
      
    return (
        <div className="profile_summary_container modal_container">
            <div className="profile_summary_section modal_content">
                <div className="modal_x_button">
                    <button onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className="modal_text_content">
                    <div className="resume_headline_text">
                        <h1>Edit education Details</h1>
                        <p>Fill Your Education Details.</p>
                    </div>
                    <div className="education-input">
                        <div className="inner-edu">
                            <label htmlFor="typeSelect"></label>
                            <select id="typeSelect" value={cate} onChange={handleChange}>
                                <option value="">Select education type</option>
                                {typeData?.type?.map((typeItem, index) => (
                                    <option key={index} value={typeItem.name}>
                                        {typeItem.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="ps-inner">
                            <input
                                type="text"
                                placeholder="Enter your institute name"
                                value={instituteName}
                                onChange={handleSearch}
                            />
                            {instituteName && filteredItems.length > 0 && (
                                <ul className="ulContainer">
                                    {filteredItems.map((item, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleClick(item)}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '10px',
                                                borderBottom: '1px solid #ccc'
                                            }}
                                        >
                                            {item.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {/* <input
                            type="text"
                            placeholder="Enter your course"
                            value={course}
                            onChange={(e) => setCourse(e.target.value)}
                        /> */}
                         {/* <label htmlFor="courseSelect">Select Course:</label> */}
      <select id="courseSelect" onChange={handleCourseChange} value={course?.name}>
        <option value="">Select a course</option>
        {isLoadingCourse ? (
          <option>Loading...</option>
        ) : (
          courseData?.course?.map((course) => (
            <option key={course._id} value={course.name} data-course={JSON.stringify(course)} >
              {course.name}
            </option>
          ))
        )}
      </select>

   
                  
                        <select id="specializationSelect" disabled={isLoadingSpecial} onChange={handleSpecializzationChange} value={specialization}>
      {initialData ?   <option value={initialData?.specialization}>{initialData?.specialization}</option> :   <option value="">Select a specialization</option>}
        {isLoadingSpecial ? (
          <option>Loading...</option>
        ) : (
          specialData?.map((specialization) => (
            <option key={specialization._id} value={specialization.name}>
              {specialization.name}
            </option>
          ))
        )}
      </select>
      <select id="courseType" onChange={handleCourseTYpeChange} value={courseType}>
        <option value="">Select a course type</option>
        {isLoadingCtype ? (
          <option>Loading...</option>
        ) : (
          cTypeData?.coursetype?.map((course) => (
            <option key={course._id} value={course.name}>
              {course.name}
            </option>
          ))
        )}
      </select>

                 
      <input
  type="text"
  placeholder="Enter start year"
  value={startYear}
  onChange={(e) => {
    const value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      handleStartYearChange(e);
    }
  }}
/>

<input
  type="text"
  placeholder="Enter passout year"
  value={passOut}
  onChange={(e) => {
    const value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      handlePassOut(e);
    }
  }}
/>
                    </div>
                    <div className="modal_button_section">
                        <button className="modal_cancel_button" onClick={onClose}>
                            Cancel
                        </button>
                        <button className="modal_submit_button" onClick={handleSubmit} disabled={isLoading}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>

            {/* "Other" Modal */}
            {showOtherModal && (
                <div className="other-modal">
                    <div className="other-modal-content">
                        <button onClick={closeOtherModal} className="modal_close_button">
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <h1>Please specify the other institute.</h1>
                         
                         <div className="education-input">
                            <br/>
                            <input
                            type="text"
                            placeholder="Enter your institute name"
                            value={inName}
                            onChange={(e) => setInName(e.target.value)}
                        />
                       
            <select
                className="country"
                value={selectedCountry}
                onChange={(e) => loadStates(e.target.value)}
            >
                <option value="">Select Country</option>
                {countries.map((country) => (
                    <option key={country.iso2} value={country.iso2}>
                        {country.name}
                    </option>
                ))}
            </select>

            <select
                className="state"
                value={selectedState}
                onChange={(e) => loadCities(e.target.value)}
                disabled={!selectedCountry}
            >
                <option value="">Select State</option>
                {states.map((state) => (
                    <option key={state.iso2} value={state.iso2}>
                        {state.name}
                    </option>
                ))}
            </select>

            <select
                className="city"
                disabled={!selectedState}
                onChange={(e) => selectCities(e.target.value)}
            >
                <option value="">Select City</option>
                {cities.map((city) => (
                    <option key={city.name} value={city.name}>
                        {city.name}
                    </option>
                ))}
            </select>
        
                        <button onClick={handleAddCollegeData}>Add institute</button>
                         </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Edu;
