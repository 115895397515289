import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../assets/images/riso_hire_logo(new).png";
import MobileNav from "./mobile_nav/MobileNav";
import { UseSelector, useSelector, useDispatch } from "react-redux";
import { logout } from "../../slice/authSlice";
import { useEffect } from "react";
import EmpRegister from "../register/employee/EmpRegister";
import EmpLogin from "../login/employee/EmpLogin";
import CandLogin from "../login/candidate/CandLogin";

const Navbar = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [change, setChange] = useState(false);
  const [empModalOpen, setEmpModalOpen] = useState(false);
  const [candModalOpen, setCandModalOpen] = useState(false);
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    dispatch(logout());
  };
  // console.log(userInfo === null);

  // useEffect(() => {
  //   if (userInfo) {
  //   }
  // }, []);
  const handleEmpModalClick = () => {
    setEmpModalOpen(!empModalOpen);
  };
  const handleCandModalClick = () => {
    setCandModalOpen(!candModalOpen);
  };

  return (
    <div className="navbar_section">
      {empModalOpen && (
        <EmpLogin
          onClose={handleEmpModalClick}
          onSubmit={handleEmpModalClick}
        />
      )}
      {candModalOpen && (
        <CandLogin
          onClose={handleCandModalClick}
          onSubmit={handleCandModalClick}
        />
      )}
      <div className="navbar_container_one">
        <div className="logo_container">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <nav className="navbar">
          <div className="nav_links_section">
            {userInfo?.verified ? (
              <ul className="nav_links_container" key={1}>
                <li className="nav_link employer_login_button">
                  <a href="">Profile</a>
                </li>
                <li className="nav_link candidate_login_button">
                  <a href="" onClick={logoutHandler}>
                    logout
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="nav_links_container" key={2}>
                <li className="nav_link join_community_button">
                  <a>Join Community</a>
                </li>
                <li className="nav_link employer_login_button">
                  <a onClick={handleEmpModalClick}>Employer Login</a>
                </li>
                <li className="nav_link candidate_login_button">
                  <a onClick={handleCandModalClick}>Candidate Login</a>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </div>
      <div className="mnav_container">
        <MobileNav />
      </div>
    </div>
  );
};

export default Navbar;
