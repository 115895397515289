import React, { useState } from 'react';
import "./UserNavbar.css";

function Notification({notification}) {
    //  console.log(notification);

     const formatDate = (notification) => {
        const date = notification.createdAt || notification.timestamp; // Use createdAt if available, otherwise timestamp
        return new Date(date).toLocaleString(); // Convert to human-readable date and time
      };
     
  return (
    <div className='box-notification'>
        <div className='box-noti-cont-header'><h2>Notifications</h2> <p>{notification.length}</p></div>
        
        <div className='box-noti-innerContainer'>
             {notification?.slice().reverse().map(noti => (
                   <div className='notification-card'>
                   <div className='notification-card-inner-left'>
                      <h5>{noti?.message}</h5>
                   </div>
                   <div className='notification-card-inner-right'>
                       <h5>{formatDate(noti).toLocaleString()}</h5>
                   </div>
              </div>
             ))}
             
        </div>
    </div>
  )
}

export default Notification