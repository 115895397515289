import React from "react";
import logo from "../../../assets/images/riso_logo.png";
import "./CandRegister.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useCandidateRegisterMutation } from "../../../slice/authApiSlice";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../../../slice/authSlice";
import Popup from "../../popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const CandRegister = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [status, setStatus] = useState("");
  const [contactNumber, setContectNumber] = useState("");
  const [location, setLocation] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const [register, { isLoading }] = useCandidateRegisterMutation();
  const [selectedButton, setSelectedButton] = useState(null);

  const registerHandler = async () => {
    if (!status) {
      return toast.error("Status is required.");
    }
    if (!firstName) {
      return toast.error("First name is required.");
    }
    if (!lastName) {
      return toast.error("Last name is required.");
    }
    if (!contactNumber || !/^\d{10}$/.test(contactNumber)) {
      return toast.error("Valid 10-digit contact number is required.");
    }
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      return toast.error("Valid email is required.");
    }
    if (!password || password.length < 6) {
      return toast.error("Password must be at least 6 characters long.");
    }
    if (password !== confirmPassword) {
      return toast.error("Password is mismatched");
    }
    if (!location) {
      return toast.error("Location is required.");
    }
    let payload = {
      email,
      password,
      firstName,
      lastName,
      status,
      contactNumber,
      location,
    };
    try {
      const res = await register(payload).unwrap();
      // console.log(res);
      
      await dispatch(setCredentials({userInfo : res.user, token: res.token }));
      await setModalOpen(true);
    } catch (error) {
      toast.error(error.data.error || error.data.message);
    }
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    setSelectedButton(e.target.value);
    setStatus(e.target.value);
    // console.log(e.target.value);
  };

  const handleModalClick = () => {
    setModalOpen(false);
  };
  return (
    <div className="cand_register_section ">
      {modalOpen && (
        <Popup onClose={handleModalClick} onSubmit={handleModalClick} />
      )}
      {/* <Navbar className="cand_reg_navbar" /> */}

      <div className="cand_register_container section_padding">
        <div className="cand_register_left_side">
          <div className="cand_reg_logo_section">
            <div>
              <img src={logo} alt="logo" />
            </div>
            <div className="cand_heading_section">
              <h2>CAREER</h2>
              <h2>EDUCATION</h2>
              <h2>SOCIAL</h2>
            </div>
          </div>
          <div className="cand_pnt_container">
            <p>Privacy Policy</p>
            <p>Terms & Conditions</p>
          </div>
        </div>
        <div className="cand_register_right_side">
          <div className="close_button_container">
            <button>
              <FontAwesomeIcon
                icon={faXmark}
                className="cross_icon"
                onClick={() => onClose()}
              />
            </button>
          </div>
          <div className="cand_register_right_side_heading">
            <h1>Create your Riso account</h1>
            <p>Join our socio-community!</p>
          </div>
          <div className="cand_register_right_side_form_container">
            <form>
              <div className="cand_reg_form_input_one ">
                <label>Work Status</label>
                <div>
                  <button
                    className={
                      selectedButton === "I am a student"
                        ? "selected"
                        : "notselect"
                    }
                    type="text"
                    value="I am a student"
                    onClick={handleButtonClick}
                  >
                    I am a student
                  </button>
                  <button
                    type="text"
                    value="I am a fresher"
                    className={
                      selectedButton === "I am a fresher"
                        ? "selected"
                        : "notselect"
                    }
                    onClick={handleButtonClick}
                  >
                    I am a fresher
                  </button>
                  <button
                    type="text"
                    value="I am experienced"
                    className={
                      selectedButton === "I am experienced"
                        ? "selected"
                        : "notselect"
                    }
                    onClick={handleButtonClick}
                  >
                    I am experienced
                  </button>
                </div>
              </div>
              <div className="cand_reg_form_input_two form_field">
                <div>
                  <label>First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="cand_reg_form_input_three form_field">
                <div>
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    value={contactNumber}
                    onChange={(e) => setContectNumber(e.target.value)}
                  />
                </div>
                <div>
                  <label>Email Id</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="cand_reg_form_input_five form_field">
                <div>
                  <label>Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <label>Confirm Password</label>
                  <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
              </div>
              <div className="cand_reg_form_input_five form_field">
                <div>
                  <label>Location</label>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
              </div>
            </form>
            <div>
              <button
                className="register_submit_button"
                onClick={registerHandler}
              >
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mc_reg_section">
        <MobileCandidateReg onClose={() => onClose()} />
      </div> */}
      {/* <Footer /> */}
    </div>
  );
};

export default CandRegister;
