import jio from "../assets/images/jio_logo.gif";
import hitachi from "../assets/images/hitachi_logo.gif";

const featuredCompaniesObjectList = [
  {
    images: jio,
    title: "Jio",
    ratings: 4.4,
    review: 20.3,
    description: "True 5g is here to unlock the limitless era",
  },
  {
    images: hitachi,
    title: "Optum",
    ratings: 4.4,
    review: 20.3,
    description: "True 5g is here to unlock the limitless era",
  },
  {
    images: jio,
    title: "VXI Global Solutions",
    ratings: 4.4,
    review: 20.3,
    description: "True 5g is here to unlock the limitless era",
  },
  {
    images: hitachi,
    title: "Hitachi Energy",
    ratings: 4.4,
    review: 20.3,
    description: "True 5g is here to unlock the limitless era",
  },
];

export default featuredCompaniesObjectList;
