import settings from "../assets/images/settings.svg";
import topcompOne from "../assets/images/riso_hire_topcomp_1.gif";
import topcompTwo from "../assets/images/riso_hire_topcomp_2.gif";
import topcompThree from "../assets/images/riso_hire_topcomp_3.gif";
import topcompFour from "../assets/images/riso_hire_topcomp_4.gif";

const topCompaniesObjectList = [
  {
    images: [topcompOne, topcompTwo, topcompThree, topcompFour],
    title: "Staff ML Data Scientist",
    subtitle: "Redbus",
  },
  {
    images: [topcompOne, topcompTwo, topcompThree, topcompFour],
    title: "Staff ML Data Scientist",
    subtitle: "Redbus",
  },
  {
    images: [topcompOne, topcompTwo, topcompThree, topcompFour],
    title: "Staff ML Data Scientist",
    subtitle: "Redbus",
  },
  {
    images: [topcompOne, topcompTwo, topcompThree, topcompFour],
    title: "Staff ML Data Scientist",
    subtitle: "Redbus",
  },
];

export default topCompaniesObjectList;
