import React from "react";
import "./MobileToCompanies.css";
import hire_arrow from "../../../assets/images/hire_arrow.png";
import topcompOne from "../../../assets/images/riso_hire_topcomp_1.gif";
import topCompaniesObjectList from "../../../objects/TopCompanies";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTrendingDetQuery } from "../../../slice/authApiSlice";
import { generatePublicUrl } from "../../../config/image";

const MobileTopCompanies = () => {
  const { data:trendingDet, isLoading: isLoadingTrending, error: trendingError } = useTrendingDetQuery();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };

  return (
    <div className="mtc_container">
      <div className="mtc_heading">
        <h1 className="section_heading">Trending Job Roles in India</h1>
      </div>
      <div className="mtc_slider_container">
        <Slider {...settings} className="mtc_slider">
          {trendingDet?.data?.map((company, index) => {
            return (
              <div className="mtc_card">
                <div className="mtc_card_content">
                  <div className="mtc_card_heading_section">
                    <div className="mtc_card_heading">
                      <h5>{company.title}</h5>
                    </div>
                    <div className="mtc_card_subheading">
                      <h6>{company.companyInfo[0].companyName}</h6>
                    </div>
                  </div>
                  <div className="mtc_card_location_exp_container">
                    <div>
                      <p>Location : {company?.location}</p>
                    </div>
                    <div className="mtc_card_exp">
                    <p>Experience : {company?.experienceStart} - {company?.experienceEnd} Years</p>
                    </div>
                  </div>
                  <div className="hire_arrow_section">
                    <img src={hire_arrow} alt="arrow_right" />
                  </div>
                </div>
                <div className="topcomp_card_image_container">
                <img src={generatePublicUrl(company?.companyInfo[0].companyLogo)} alt="logo" />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="mtc_button_section">
        <button>Explore more</button>
      </div>
    </div>
  );
};

export default MobileTopCompanies;
