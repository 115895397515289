import React from "react";
import "./Footer.css";
import play from "../../assets/images/Google_Play_Store_badge_EN_1.png";
import apple from "../../assets/images/Group_165074.png";
import riso from "../../assets/images/Group_165071.png";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="ftr_container">
      <div className="ftr_hd_1">
        <div className="ftr_abt">
          <h3>Riso Consulting Private Limited.</h3>
          <p>
          RisoApp is more than just a platform—it’s a complete community support system for your educational and career journey. Whether you’re a student, parent, or professional, we invite you to explore RisoApp, connect with our expert counselors, and take the next step towards a successful and fulfilling future.
          </p>
        </div>
        <div className="ftr_link_main">
          <div className="ftr_lnk">
            <a href="https://risoapp.com/about">About Riso</a>
            <a>Riso Services</a>
            <a>Work at Riso</a>
            <a>Blogs</a>
            <a>Contact Us</a>
            <a>Help & Support</a>
          </div>
          <div className="ftr_lnk1">
            <a>Privacy Policy</a>
            <a>Terms & Conditions</a>
            <a>F&Q</a>
            <a>Fraud Alert</a>
            <a>Trust & Safety</a>
            <a>Report Issue</a>
          </div>
        </div>
        <div className="ftr_lnk2">
          <div className="ftr_app">
            <img src={play} alt="plastore image"></img>
            <img src={apple} alt="apple store"></img>
          </div>
          <div className="ftr_follow_us">
            <img src={riso} alt="riso logo" className="logo_main"></img>
            <div className="ftr_follow_2">
              <h3>Follow us on social media</h3>
              <div className="ftr_icons">
                <i>
                  <FaFacebookF />
                </i>
                <i>
                  <FaLinkedinIn />
                </i>
                <i>
                  <FaXTwitter />
                </i>
                <i>
                  <FaInstagram />
                </i>
                <i>
                  <FaYoutube />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ftr_hd_2">
        <p>
          All trademarks are the property of their respective owners. All rights
          received © 2024 Riso Consulting Private Limited.{" "}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
