import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import MobileHeader from "./mheader/MobileHeader";
import { useFindSkilledJobsMutation } from "../../slice/authApiSlice";
import { useNavigate } from "react-router-dom";
import cImg from "../../assets/company.png"
import { generatePublicUrl } from "../../config/image";
import CandLogin from "../login/candidate/CandLogin";
import EmpLogin from "../login/employee/EmpLogin";

const Header = () => {
  const [skills, setSkills] = useState([]);
  const [candModalOpen, setCandModalOpen] = useState(false);
  const [empModalOpen, setEmpModalOpen] = useState(false);
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [findSkilledJobs, { isLoading: findLoading }] = useFindSkilledJobsMutation();
  const [jobResults1, setJobResults1] = useState([]);
  const [showSuggestionsUser, setShowSuggestionsUser] = useState(false);
  const suggestionsUserRef = useRef(null);
  const navigate = useNavigate();

  const handleCandModalClick = () => {
    setCandModalOpen(!candModalOpen);
  };
  const handleEmpModalClick = () => {
    setEmpModalOpen(!empModalOpen);
  };

  const handleSkillsChange = (e) => {
    const value = e.target.value;
    setSkills(value.split(',').map(skill => skill.trim())); // Convert input into an array of skills
  };

  const handleSearch = async () => {
    try {
      const requestBody = {
        skills,
        experience,
        location,
      };

     
      const response = await findSkilledJobs(requestBody).unwrap();
      setShowSuggestionsUser(true);
      // console.log(response);
      
      setJobResults1(response);
    } catch (error) {
      console.error("Error searching jobs:", error);
      alert("Failed to search jobs.");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsUserRef.current && !suggestionsUserRef.current.contains(event.target)) {
        setShowSuggestionsUser(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [suggestionsUserRef]);

  const visitJobData = (data) => {
    navigate(`/job/${data._id}/details`, { state: { data } });
  }
  return (
    <>      {candModalOpen && (
      <CandLogin
        onClose={handleCandModalClick}
        onSubmit={handleCandModalClick}
      />
    )}

{empModalOpen && (
        <EmpLogin
          onClose={handleEmpModalClick}
          onSubmit={handleEmpModalClick}
        />
      )}
    <div className="header_container">
      <div className="header_section">
        <div className="hading_search_section">
          <div className="hading_section">
            <h1>Discover your next <br/> career move!</h1>
            {/* <h4>5 lakh+ jobs for you to explore</h4> */}
          </div>
          <p className="left-header-text">Need personalized <a href="">career advice?</a></p>
          <div className="sarch_section">
            
            <div className="header_search_container">
              <FontAwesomeIcon
                className="search_icon"
                icon={faMagnifyingGlass}
              />
              <input
                 className="header_search"
                 type="search"
                 placeholder="Enter skills (comma separated)"
                 value={skills.join(', ')}
                 onChange={handleSkillsChange}
              />
            </div>

            <div className="header_select_container">
              <FontAwesomeIcon className="experience_icon" icon={faBriefcase} />
              <input
                 placeholder="Experience"
                 value={experience}
                 onChange={(e) => setExperience(e.target.value)}
              />
            </div>

            <div className="header_input_container">
              <div className="heder_input">
                <input
                  
              type="text"
              placeholder="Enter location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
                
                />
              </div>
            </div>
            <div>
            <button className="header_search_button" onClick={handleSearch} disabled={findLoading}>
            {findLoading ? "Searching..." : "Search"}
          </button>
            </div>
          </div>
          <p className="right-header-text">Would you like to <a onClick={handleEmpModalClick}>post a Job?</a></p>
          {/* <div className="header_links">
          <p>Need personalized career advice?</p>
          <p>Would you like to post a Job?</p>
        </div> */}
          <div className="header_login_buttons">
            <button className="header_login_button_one">Employer Login</button>
            <button className="header_login_button_two">Candidate Login</button>
          </div>
        </div>
        {showSuggestionsUser && (
        <div className="suggestions_box2" ref={suggestionsUserRef}>
           {
            jobResults1.map(result=> {
             return(
              <div className="pr-section">
                 <div className="pr-section-inner">
                  <div className="pr-img-fg">
                   {result?.companyDetails?.companyLogo === "" ?  <img src={cImg} /> :  <img src={generatePublicUrl(result?.companyDetails?.companyLogo)} />}
                  </div>
                  <div className="pr-desc-fr">
                    <h2>{result?.title}</h2>
                    <p>{result.companyDetails.companyName}</p>
                    <p>{result?.numPosition}</p>
                  </div>
                 </div>
                 <div className="pr-fg-btn">
                  <button onClick={handleCandModalClick}>Visit Job Details page</button>
                 </div>
              </div>
             )
            })
           }
        </div>
      )}
      </div>
      <div className="mobile_header_section_container">
        <MobileHeader />
      </div>

      
    </div>


</>

  );
};

export default Header;
