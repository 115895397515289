import React from "react";
import "./MobileTesti.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profileImg from "../../../assets/images/hire_profile_img.jpg";
import testimonialsObjectList from "../../../objects/Testimonials";
import { useFeedbackGetQuery } from "../../../slice/authApiSlice";
import { generatePublicUrl } from "../../../constants";

const MobileTesti = () => {

  const { data:feedbackGet, isLoading: isLoadingjobDet, error: jobError } = useFeedbackGetQuery();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    arrows: false,
  };
  return (
    <div className="mt_container">
      <div className="mt_heading">
        <h1>Testimonials</h1>
      </div>
      <div className="mt_card_container">
        <Slider {...settings} className="mt_slider">
          {feedbackGet?.feedback.map((obj, indx) => {
            return (
              <div className="mt_card">
                <div className="mt_card_img_container">
                {obj.user?.profilePictures.length== 0 ? <img src={profileImg} alt="profile-img" /> :<img src={generatePublicUrl(obj.user?.profilePictures[0]?.img)} alt="profile-img" />}
                </div>
                <div className="mt_card_description">
                  <p className="mt_card_user_message">{obj.message}</p>
                  <p className="mt_card_user_designation">rating{obj.rating}</p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default MobileTesti;
