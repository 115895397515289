import React, { useState } from "react";
import "./MobilePopSarch.css";
import { usePopularDetQuery } from "../../../slice/authApiSlice";

const MobilePopSearch = () => {
  const { data: popularDet, isLoading: isLoadingpopularDet, error: popularError } = usePopularDetQuery();
  
  // State to control whether to show all categories or only a few
  const [showAll, setShowAll] = useState(false);

  // Toggling between showing limited and full list
  const handleViewMore = () => {
    setShowAll((prevState) => !prevState);
  };

  // Determine how many categories to show based on state
  const categoriesToShow = showAll ? popularDet?.data : popularDet?.data?.slice(0, 6);

  return (
    <div className="mps_container">
      <div className="mps_heading_section">
        <h1>Popular Search Categories</h1>
      </div>
      <div className="mps_category_container">
        {categoriesToShow?.map((data, index) => {
          return (
            <div className="mps_category" key={index}>
              <div className="mps_category_heading">
                <h4>{data?.name}</h4>
              </div>
              <div className="mps_category_text">
                <p>{data?.opportunities} Opportunities </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mps_button_container">
        <button onClick={handleViewMore}>
          {showAll ? "View Less" : "View More"}
        </button>
      </div>
    </div>
  );
};

export default MobilePopSearch;
