import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./details.css";
import cImg from "../../assets/company.png"
import { generatePublicUrl } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setJob } from '../../slice/updateJobSlice';

function StausUpdateDetailsPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state;
    const dispatch = useDispatch();
    const updateJob = useSelector(state => state.updateJob.updateJob);


    useEffect(() => {
        const data = location.state?.data;
        
        // Ensure data is defined before dispatching
        if (data) {
            dispatch(setJob(data));
        }
        // console.log(updateJob, "update");
        
    }, [location.state, dispatch, updateJob]);


    // console.log(data);

    const statusMap = {
        applied: 20,
        'under review': 40,
        shortlisted: 60,
        rejected: 80,
        hired: 100,
    };

    const progress = statusMap[data?.status] || 0;


    const visitUsePageForMoreInfo = (neDAta) => {
        
        
        navigate(`/candidate/details/${neDAta._id}`, { state: { neDAta, jobId: data._id } });
    }

    return (
        <>
         <div className="job-gg">
        
           <h2>Job Details Page</h2>
         
                <Box className sx={{ width: '70%' }}>
                    <Typography variant="body1" gutterBottom style={{ color: "white" }}>
                        Progress ({"applied candidate"} - {data.applicant.length}%)
                    </Typography>
                    <LinearProgress
                        sx={{
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: 'white', // White color for the progress bar
                            }
                        }}
                        variant="determinate" value={progress}
                    />
                </Box>
    </div>
        <section className='AppliedJobs'>
            <div className='Applied-inner'>
                <div className='applied-img'>
                    {/* <img src={cImg} /> */}
                    {data?.companyDetails?.companyLogo === "" ? <img src={cImg} alt="Company logo" /> :  <img src={generatePublicUrl(data?.companyDetails?.companyLogo)} alt="Company logo" /> }
                </div>

                <section className='mob-det-section-data'>
                    <div className='hide-in-desk-strong'>
                <div className='hide-in-desk-strong-img'>{data?.companyDetails?.companyLogo === "" ? <img src={cImg} alt="Company logo" /> :  <img src={generatePublicUrl(data?.companyDetails?.companyLogo)} alt="Company logo" /> }</div>
                <div>Applied candidate details</div>
                
                </div>
                </section>
                <div className='applied-desc'>
                    <h1>{data?.title} ({data?.companyDetails?.companyName})</h1>
                    <h2>job location : {data?.location}</h2>
                    <h3>Description:</h3>
                    <p>{data?.description}</p>

                    <h3>Applied candidate:</h3>
                    {data.applicant.length === 0 ? "no candidate applied yet" : 
                    
                    <div className='udbfbd'>{data?.applicant.map(ap => {
                        return(
                            <div >
                                <div className='user_applied_card' onClick={() => visitUsePageForMoreInfo(ap)}>
                                  <div className='user_applied_profile_m'>
                                    <img src={generatePublicUrl(ap?.userId?.profilePictures[0].img)}/>
                                  </div>
                                  <div className='user_applied_profile_d'>
                                        <h2>{ap?.userId?.firstName} {ap?.userId?.lastName}</h2>
                                        <p>{ap?.userId?.email}</p>
                                  </div>
                            </div>
                            </div>
                        )
                    })}</div>
                    
                    }
                  
                </div>
            </div>
        </section>
        </>
    );
}

export default StausUpdateDetailsPage;
