// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Kumbh Sans", sans-serif;
  height: auto;
}

body {
  background-color: #f8f8f8;
  height: auto;
}

a {
  color: unset;
  text-decoration: none;
}

.section_padding {
  padding: 4rem 11rem;
}

.section_margin {
  margin: 4rem 6rem;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAIA;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;EACT,uBAAuB;EACvB,qCAAqC;EACrC,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins&display=swap\");\r\n@import url(\"https://fonts.googleapis.com/css2?family=Montserrat&display=swap\");\r\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto&display=swap\");\r\n@import url(\"https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap\");\r\n* {\r\n  box-sizing: border-box;\r\n  padding: 0;\r\n  margin: 0;\r\n  scroll-behavior: smooth;\r\n  font-family: \"Kumbh Sans\", sans-serif;\r\n  height: auto;\r\n}\r\n\r\nbody {\r\n  background-color: #f8f8f8;\r\n  height: auto;\r\n}\r\n\r\na {\r\n  color: unset;\r\n  text-decoration: none;\r\n}\r\n\r\n.section_padding {\r\n  padding: 4rem 11rem;\r\n}\r\n\r\n.section_margin {\r\n  margin: 4rem 6rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
