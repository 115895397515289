import React, { useEffect, useState } from "react";
import "./UserProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faPencil, faDeleteLeft, faTrash, faTrashArrowUp, faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import resumeIcon from "../../../assets/images/resume_icon.svg";
import ResumeHeadline from "../../../components/modal/ResumeHeadline";
import Skils from "../../../components/modal/Skils";
import ProfileSummary from "../../../components/modal/ProfileSummary";
import Project from "../../../components/modal/Projects";
import WorkExperience from "../../../components/modal/WorkExperience";
import cImg from "../../../assets/company.png"
import Education from "../../../components/modal/Education";
import PersonalDetails from "../../../components/modal/PeresonalDetails";
import CareerProfile from "../../../components/modal/CareerProfile";
import JobCreate from "../../../components/modal/JobCreate";
import moment from "moment";
import { useSkillDetQuery, useInstituteDetQuery, useProfileCompMutation, useRecJobQuery, useUplaodResumeAddMutation, useEducationDeleteMutation, useEmpDeleteMutation, useProDeleteMutation } from "../../../slice/authApiSlice";
import { setInstituteData } from "../../../slice/instituteSlice";
import { setCredentials } from "../../../slice/authSlice";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { generatePublicUrl } from "../../../constants";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Company from "../../../components/modal/Company";
import { toast } from "react-toastify";
import Edu from "../../../components/modal/Edu";
import ProjectEdit from "../../../components/modal/ProjectEdit";
import EditCarrer from "../../../components/modal/EditCarrer";
import EditPersonal from "../../../components/modal/EditPersonal";
import EditWork from "../../../components/modal/EditWork";
import JobEdit from "../../../components/modal/JobEdit";






const UserProfile = () => {
  const user = useSelector((state) => state.auth.userInfo);

  const token = useSelector((state) => state.auth.token);
  const instituteDatanew = useSelector((state) => state.institute.instituteData);
  const { data: instituteData, isLoading: isLoadingInstitute, error: institteError } = useInstituteDetQuery();
  const { data, isLoading } = useSkillDetQuery();
  const [initialData, setInitialData] = useState(null);
  const [profileComp, { isLoading: prLoading }] = useProfileCompMutation();
  const [uplaodResumeAdd, { isLoading: resumeLoading }] = useUplaodResumeAddMutation();
  const [educationDelete, { isLoading: educationDeleteLoading }] = useEducationDeleteMutation();
  const [empDelete, { isLoading: empDeleteLoading }] = useEmpDeleteMutation();
  const [proDelete, { isLoading: proDeleteLoading }] = useProDeleteMutation();


  // console.log(user, token, instituteData);


  const [resumeHeadlineOpener, setResumeHeadlineOpener] = useState(false);
  const dispatch = useDispatch();
  const [profileSummaryOpener, setProfileSummaryOpener] = useState(false);
  const [workExpOpener, setWorkExpOpener] = useState(false);
  const [personalOpener, setPersonalOpener] = useState(false);
  const [jobOpener, setJobOpener] = useState(false);
  const [jobEditOpener, setJobEditOpener] = useState(false);
  const [companyOpener, setCompanyOpener] = useState(false);
  const [carrerOpener, setCarrerOpener] = useState(false);
  const [projectOpener, setProjectOpener] = useState(false);
  const [educationOpener, setEducationOpener] = useState(false);
  const [eduOpener, setEduOpener] = useState(false);
  const [projectEditOpener, setProjectEditOpener] = useState(false);
  const [careerEditOpener, setCareerEditOpener] = useState(false);
  const [editPersonal, setEditPersonal] = useState(false);
  const [editWork, setEditWork] = useState(false);
  const [skillsOpener, setSkillsOpener] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [profileSummary, setProfileSummary] = useState("");
  const [resumeSummary, setResumeSummary] = useState("");
  const [resumePdf, setResumePdf] = useState("");
  const [summary, setSummary] = useState("");
  const [progress, setProgress] = useState(0);
  const jobs = useSelector((state) => state.job.jobData);
  const { data: recJob, isLoading: recLoading } = useRecJobQuery();


  let edu = user?.education?.filter(edu => edu.category === 'graduate');
  let emp = user?.employement?.filter(emp => emp.leaveDate === null);

  // console.log(jobs);

  const activeJobs = jobs?.filter(job => job.status === true);
const activeJobCount = activeJobs?.length;

// Find inactive jobs (status false)
const inactiveJobs = jobs?.filter(job => job.status === false);
const inactiveJobCount = inactiveJobs?.length;


  const handleResume = (e) => {
    setResumePdf(e.target.files[0]);
    // console.log(e.target.files[0], "file++++++++++++++++++++++>>>>>>>>>>>>>>>>>>");

  };

  const handleDownLoad = () => {
    // Generate the public URL for the resume PDF
    let fileUrl = generatePublicUrl(user?.resumePdf);

    // console.log(fileUrl);

    // Create a temporary anchor element to trigger the download
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = user?.resumePdf; // Set the file name for download

    // Append the anchor to the document body, click it, and remove it
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

  }

  const profileSections = {
    resume: !!user?.basicDet?.[0]?.resume,
    profileSummary: !!user?.basicDet?.[0]?.summary,
    skills: user?.keySkills?.length > 0,
    workExperience: user?.employement?.length > 0,
    education: user?.education?.length > 0,
    projects: !!user?.projects?.length,
    accomplishments: !!user?.accomplishments?.length,
    careerProfile: !!user?.careerPreferences?.length,
    personalDetails: !!user?.personalDetails,
  }

  const calculateProfileCompletion = () => {
    const totalSections = Object.keys(profileSections).length;
    const completedSections = Object.values(profileSections).filter(Boolean).length;
    return Math.round((completedSections / totalSections) * 100);
  };

  const profileCompletion = calculateProfileCompletion();

  // console.log(profileCompletion);

  const prevCompletion = user?.profileCompletion;

  // console.log();


  useEffect(() => {
    setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  }, [])

  const updateProfileCompletion = async () => {
    try {
      let payload = {
        profileCompletion: profileCompletion,
        userId: user?.id
      }
      let res = await profileComp(payload);
      // console.log(res, "res");
      dispatch(dispatch(setCredentials({ userInfo: res?.data?.updatedUser, token: token })))

      // console.log('Profile completion updated:', response.data);
    } catch (error) {
      console.error('Error updating profile completion:', error);
    }
  };

  useEffect(() => {
    if (prevCompletion === profileCompletion) {
      // console.log("enter");

    } else {
      // console.log("enter here");

      updateProfileCompletion()

    }
  }, [user, token])





  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    onDrop: (files) => {
      setAcceptedFile(files[0]);
      // console.log(files[0]);

    },
  });


  useEffect(() => {
    if (instituteData) {
      dispatch(setInstituteData(instituteData.institute)); // Assuming instituteData has an `institute` field
      // console.log(instituteDatanew);

    }
  }, [dispatch, instituteData]);


  // useEffect(() => {
  //   console.log(user);
  //   setSummary(user?.basicDet[0]?.summary);

  // },[user])

  // console.log(data.categories);


  const handleAcceptFiles = async () => {

    const form = new FormData();
    form.append("resumePdf", acceptedFile);

    try {
      let res = await uplaodResumeAdd(form).unwrap();
      // console.log(res, "uploaded");
      dispatch(setCredentials({ userInfo: res.user, token: token }));
      setAcceptedFile(null);
    } catch (error) {
      // console.log(error);
      setAcceptedFile(null);
    }



  };

  const handleResumeHeadlineOpener = () => {
    setResumeHeadlineOpener(!resumeHeadlineOpener);
  };

  const handleProfileSummaryOpener = () => {
    setProfileSummaryOpener(!profileSummaryOpener);
  };

  const handleWorkExpOpener = () => {
    setWorkExpOpener(!workExpOpener);
  };
  const handleJobOpener = () => {
    setJobOpener(!jobOpener);
  };
  const handleCompanyOpener = () => {
    setCompanyOpener(!companyOpener);
  };
  const handleProjectOpener = () => {
    setProjectOpener(!projectOpener);
  };
  const handleCarrerOpener = () => {
    setCarrerOpener(!carrerOpener);
  };

  const handlePersonalDetOpener = () => {
    setPersonalOpener(!personalOpener);
  };

  const handleSkillsOpener = () => {
    setSkillsOpener(!skillsOpener);
  };
  const handleEducationOpener = () => {
    setEducationOpener(!educationOpener)
  };
  const handleEdu = (data) => {
    setEduOpener(!eduOpener);
    setInitialData(data)
  };

  const handleEditWork = (data) => {
    setEditWork(!editWork);
    setInitialData(data)
  };
  const handleProjectEdit = (data) => {
    setProjectEditOpener(!projectEditOpener);
    setInitialData(data)
  };
  const handlePersonalEdit = (data) => {
    setEditPersonal(!editPersonal);
    setInitialData(data)
  };
  const handleJobEdit = (data) => {
    setJobEditOpener(!jobEditOpener);
    setInitialData(data)
  };
  const handleCarrerEdit = (data) => {
    setCareerEditOpener(!careerEditOpener);
    setInitialData(data)
  };
  const handleProfileSummarySubmit = (summary) => {
    setProfileSummary(summary);
    setProfileSummaryOpener(false); // Close the modal after submission
  };

  const handleResumeSummarySubmit = (resume) => {
    setResumeSummary(resume);
    setResumeHeadlineOpener(false); // Close the modal after submission
  };


  const educationDeleteById = async (data) => {
    let payload = {
      educationId: data
    }
    try {
      let res = await educationDelete(payload).unwrap();
      dispatch(setCredentials({ userInfo: res.updatedUser, token: token }));
      // toast.success(res.message)
      // console.log(res);

    } catch (error) {

    }
  }
  const empDeleteById = async (data) => {
    let payload = {
      empId: data
    }
    try {
      let res = await empDelete(payload).unwrap();
      dispatch(setCredentials({ userInfo: res.updatedUser, token: token }));
      // toast.success(res.message)
      // console.log(res);

    } catch (error) {

    }
  }

  const proDeleteById = async (data) => {
    let payload = {
      proId: data
    }
    try {
      let res = await proDelete(payload).unwrap();
      dispatch(setCredentials({ userInfo: res.updatedUser, token: token }));
      // toast.success(res.message)
      // console.log(res);

    } catch (error) {

    }
  }

  const renderRecruterSec = () => {
    return (
      <div className="user_profile_container">
        {/* recruter sec */}
        {jobOpener && (
          <JobCreate
            onClose={handleJobOpener}
            onSubmit={handleJobOpener}
          />
        )}
        {companyOpener && (
          <Company
            onClose={handleCompanyOpener}
            onSubmit={handleCompanyOpener}
          />
        )}

        {jobEditOpener && (
          <JobEdit
            onClose={handleJobEdit}
            onSubmit={handleJobEdit}
            initialData={initialData}
          />
        )}
        {skillsOpener && (
          <Skils onClose={handleSkillsOpener} onSubmit={handleSkillsOpener} />
        )}
        {profileSummaryOpener && (
          <ProfileSummary
            onClose={handleProfileSummaryOpener}
            onSubmit={handleProfileSummarySubmit}
            initialSummary={profileSummary}
          />
        )}
        {workExpOpener && (
          <WorkExperience
            onClose={handleWorkExpOpener}
            onSubmit={handleWorkExpOpener}
          />
        )}
        {educationOpener && (
          <Education
            onClose={handleEducationOpener}
            onSubmit={handleEducationOpener}
          />
        )}
        {eduOpener && (
          <Edu
            onClose={handleEdu}
            onSubmit={handleEdu}
          />
        )}
        {projectOpener && (
          <Project
            onClose={handleProjectOpener}
            onSubmit={handleProjectOpener}
          />
        )}
        {personalOpener && (
          <PersonalDetails
            onClose={handlePersonalDetOpener}
            onSubmit={handlePersonalDetOpener}
          />
        )}
        {carrerOpener && (
          <CareerProfile
            onClose={handleCarrerOpener}
            onSubmit={handleCarrerOpener}
          />
        )}
        <div className="profile_performance_section">
          <div className="profile_performance_section_heading">
            <h2>Recruter Data</h2>
          </div>
          <div className="profile_performance_stats_section">
            <div className="performance_stats_container">
              <h2>{jobs?.length}</h2>
              <p>My job posts</p>
            </div>
            <div className="performance_stats_container">
              <h2>{activeJobCount}</h2>
              <p>Active job post</p>
            </div>
            <div className="performance_stats_container">
              <h2>{inactiveJobCount}</h2>
              <p>Inactive job post</p>
            </div>
            <div className="performance_stats_container">
              <h2>{user?.savedCandidate?.length}</h2>
              <p>Saved Candidate</p>
            </div>
           
          </div>
        </div>

        <div className="user_profile_edit_fields_container">
          <div className="user_feeds_container_left_side">
            <Box position="relative" display="inline-flex">
              <CircularProgress
                color="success"
                variant="determinate"
                value={user?.profileCompletion || 0}
                style={{ width: '200px', height: '200px' }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className="user-profile-img">
                  {user?.profilePictures.length === 0 ? <p className="prof_icon">{user?.firstName[0] + user?.lastName[0]}</p> : <img src={generatePublicUrl(user?.profilePictures[0].img)} />}
                </div>
              </Box>
            </Box>

            <div className="user-up-name">
              <h6>{user.firstName + " " + user.lastName}</h6>
              {/* <p>B.tech (Computer science)</p> */}
              <div className="profile_completion_rate">
                <p>{user?.profileCompletion}% Profile Completed</p>
              </div>
            </div>

            <div className="user-up-loc">
              <div className="use-inner">
                <p>Role</p>
                <h6 style={{ textTransform: "capitalize" }}>{user?.role}</h6>
              </div>
              <div className="use-inner">
                <p>Location</p>
                <h6 style={{ textTransform: "capitalize" }}>{user?.location}</h6>
              </div>
            </div>
            <div className="user-btn-update">
              <p>Last updated on {moment(user?.updatedAt).calendar()}</p>
            </div>
          </div>
          <div className="edit_fields_container_right_side">
            {user?.companyData === null ? <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Add Company Details</h2>
                <button onClick={handleCompanyOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              <p>
                {
                  (user?.basicDet?.length === 0 || user?.basicDet?.[0]?.resume === "")
                    ? "It is the first thing a candidate will notice in your profile. Write a concise headline introducing your company."
                    : user?.basicDet?.[0]?.resume
                }

              </p>
            </div> :
              <div className="edit_fields_container_right_side_card">
                <div>
                  <h2>Edit Company Details</h2>
                  <button onClick={handleCompanyOpener}>
                    <FontAwesomeIcon icon={faPencil} />
                  </button>
                </div>
                <p>{user?.companyData?.companyName} - {user?.companyData?.address} , {user?.companyData?.state}</p>
              </div>
            }
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Add Job</h2>
                <button onClick={handleJobOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              <p>
                {
                  (user?.basicDet?.length === 0 || user?.basicDet?.[0]?.resume === "")
                    ? "It is the first thing a candidate will notice in your profile. Write a concise job details"
                    : user?.basicDet?.[0]?.resume
                }

              </p>
            </div>
            <div className="edit_fields_container_right_side_card1">
              <div className="myCdetails">
                <h2>My Company Details</h2>
                <div className="inner_c_details">
                  <section className="ing_c">
                   {user?.companyData?.companyLogo === null ?  <img src={cImg} /> :  <img src={generatePublicUrl(user?.companyData?.companyLogo)} />}
                  </section>
                  <section className="upper_ingc">
                    <section className="ing_c-det">
                      <h3>Company Name: </h3>
                      <p>{user?.companyData?.companyName}</p>
                    </section>
                    <section className="ing_c-det">
                      <h3>Contact Number: </h3>
                      <p>{user?.companyData?.phone}</p>
                    </section>
                    <section className="ing_c-det">
                      <h3>Fax Number: </h3>
                      <p>{user?.companyData?.fax}</p>
                    </section>
                    <section className="ing_c-det">
                      <h3>Number of office: </h3>
                      <p>{user?.companyData?.numberOfOffice}</p>
                    </section>
                    <section className="ing_c-det">
                      <h3>Number of employees: </h3>
                      <p>{user?.companyData?.numberOfEmployees}</p>
                    </section>
                  </section>
                </div>
              </div>
              <div>

                <div className="my-recu-jobs-right">
                  <h2>My Added jobs ({jobs?.length})</h2>
                  <div className="my-recu-jobs-right-table">
                    <TableContainer style={{ width: "100%" }}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Job Id</TableCell>
                            <TableCell align="left">Job Name</TableCell>
                            <TableCell align="right">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {jobs.map((row) => (
                            <TableRow
                              key={row._id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {(row._id).slice(0, 10).concat('...')} <FontAwesomeIcon icon={faCopy} />
                              </TableCell>
                              <TableCell align="left">{row.title}</TableCell>
                              <TableCell className="btn_btn_edit" align="right" onClick={() => handleJobEdit(row)}>Edit</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer></div>
                </div>

              </div>
            </div>





          </div>
        </div>
      </div>
    )
  }


  const renderUserSec = () => {
    return (
      <div className="user_profile_container">
        {resumeHeadlineOpener && (
          <ResumeHeadline
            onClose={handleResumeHeadlineOpener}
            onSubmit={handleResumeSummarySubmit}
            initialResumeSummary={resumeSummary}
          />
        )}
        {skillsOpener && (
          <Skils onClose={handleSkillsOpener} onSubmit={handleSkillsOpener} />
        )}
        {profileSummaryOpener && (
          <ProfileSummary
            onClose={handleProfileSummaryOpener}
            onSubmit={handleProfileSummarySubmit}
            initialSummary={profileSummary}
          />
        )}
        {workExpOpener && (
          <WorkExperience
            onClose={handleWorkExpOpener}
            onSubmit={handleWorkExpOpener}
          />
        )}
        {educationOpener && (
          <Education
            onClose={handleEducationOpener}
            onSubmit={handleEducationOpener}
          />
        )}

        {eduOpener && (
          <Edu
            onClose={handleEdu}
            onSubmit={handleEdu}
            initialData={initialData}
          />
        )}
        {projectEditOpener && (
          <ProjectEdit
          onClose={handleProjectEdit}
          onSubmit={handleProjectEdit}
          initialData={initialData}
          />
        )}
           {editPersonal && (
          <EditPersonal
          onClose={handlePersonalEdit}
          onSubmit={handlePersonalEdit}
          initialData={initialData}
          />
        )}
             {editWork && (
          <EditWork
          onClose={handleEditWork}
          onSubmit={handleEditWork}
          initialData={initialData}
          />
        )}
            {careerEditOpener && (
          <EditCarrer
          onClose={handleCarrerEdit}
          onSubmit={handleCarrerEdit}
          initialData={initialData}
          />
        )}
        {projectOpener && (
          <Project
            onClose={handleProjectOpener}
            onSubmit={handleProjectOpener}
          />
        )}
        {personalOpener && (
          <PersonalDetails
            onClose={handlePersonalDetOpener}
            onSubmit={handlePersonalDetOpener}
          />
        )}
        {carrerOpener && (
          <CareerProfile
            onClose={handleCarrerOpener}
            onSubmit={handleCarrerOpener}
          />
        )}
        <div className="profile_performance_section">
          <div className="profile_performance_section_heading">
            <h2>Profile performance</h2>
          </div>
          <div className="profile_performance_stats_section">
            <div className="performance_stats_container">
              <h2>{user?.searchAppearances}</h2>
              <p>Search Appearances</p>
            </div>
            <div className="performance_stats_container">
              <h2>{user?.visitedByRecruiters?.length}</h2>
              <p>Recruiters Visits</p>
            </div>

            <div className="performance_stats_container">
              <h2>{!recJob?.jobs ? 0 : recJob?.jobs.length}</h2>
              <p>Recommanded Jobs</p>
            </div>
            <div className="performance_stats_container">
              <h2>{user?.savedJobs?.length}</h2>
              <p>Saved Jobs</p>
            </div>
           
          </div>
        </div>

        <div className="user_profile_edit_fields_container">
          <div className="user_feeds_container_left_side">
            <Box position="relative" display="inline-flex">
              <CircularProgress
                color="success"
    
                variant="determinate"
                value={user?.profileCompletion || 0}
                style={{ width: '200px', height: '200px' }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className="user-profile-img">
                  {user?.profilePictures.length === 0 ? <p className="prof_icon">{user?.firstName[0] + user?.lastName[0]}</p> : <img src={generatePublicUrl(user?.profilePictures[0].img)} />}
                </div>
              </Box>
            </Box>

            <div className="user-up-name">
              <h6>{user.firstName + " " + user.lastName}</h6>
              {!edu[0]?.course ? <p>Add graducation details</p> : <p>{edu[0]?.course} &nbsp; ({edu[0]?.specialization})</p>}
              <div className="profile_completion_rate">
                <p>{user?.profileCompletion}% Profile Completed</p>
              </div>
            </div>

            <div className="user-up-loc">
              <div className="use-inner">
                <p>Experience</p>
                {!emp[0]?.totalExp ? <h6>Add total Exprience</h6> : <h6>{emp[0]?.totalExp}</h6>}
                {/* <h6>{emp[0]?.totalExp}</h6> */}
              </div>
              <div className="use-inner">
                <p>Location</p>
                <h6 style={{ textTransform: "capitalize" }}>{user?.location}</h6>
              </div>
            </div>
            <div className="user-btn-update">
              <p>Last updated on {moment(user?.updatedAt).calendar()}</p>
            </div>
          </div>
          <div className="edit_fields_container_right_side">
            <div className="edit_fields_container_drop_zone">
              <div className="dropzone-heading-section">
                <div className="dropzone-heading">
                  <h2>Add Resume</h2>
                </div>
                <p>
                  10 important tips for
                  <span>
                    <a href="#">Resume Writing</a>
                  </span>
                  with examples
                </p>
              </div>
              {acceptedFile && (
                <div className="uploaded_flies_section">
                  {acceptedFile && (
                    <div className="uploaded_flies_container">
                      <div className="uploaded_file_details_section">
                        <div
                          key={acceptedFile.path}
                          className="uploaded_file_details"
                        >
                          <img src={resumeIcon} alt="file" />{" "}
                          <p>{acceptedFile.path}</p>
                        </div>
                        <button onClick={handleAcceptFiles}>Uplaod</button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {user?.resumePdf && (
                <div className="uploaded_flies_section">
                  {user?.resumePdf && (
                    <div className="uploaded_flies_container">
                      <div className="uploaded_file_details_section">
                        <div
                          className="uploaded_file_details"
                        >
                          <img src={resumeIcon} alt="file" />{" "}
                          <p>{user?.resumePdf}</p>
                        </div>
                        <button onClick={handleDownLoad}>DownLoad</button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <button className="dropzone-button">
                  <FontAwesomeIcon icon={faPaperclip} /> Upload Here
                </button>
              </div>
            </div>
            {/* <input type="file" onChange={handleResume} /> */}
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Resume Headline</h2>
                <button onClick={handleResumeHeadlineOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              <p>
                {
                  (user?.basicDet?.length === 0 || user?.basicDet?.[0]?.resume === "")
                    ? "It is the first thing recruiters notice in your profile. Write a concise headline introducing yourself to employers."
                    : user?.basicDet?.[0]?.resume
                }

              </p>
            </div>
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Profile Summary</h2>
                <button onClick={handleProfileSummaryOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>

              <p>
                {
                  (user?.basicDet?.length === 0 || user?.basicDet?.[0]?.summary === "")
                    ? "Highlight your key career achievements to help employers know your potential."
                    : user?.basicDet?.[0]?.summary
                }
              </p>
            </div>
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Skills</h2>
                <button onClick={handleSkillsOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              <p>
                {user?.keySkills?.length === 0 ? " Add skills that best define your expertise, for e.g, Direct Marketing, Oracle, Java, etc."
                  :
                  user?.keySkills?.map(key => {
                    return (
                      <>{key.skill},</>
                    )
                  })
                }

              </p>
            </div>
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Work Experience</h2>
                <button onClick={handleWorkExpOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              {user?.employement?.length === 0 ? <p>
                Your employment details will help recruiters understand your
                experience.
              </p> :

                user?.employement?.map((emp) => (
                  <div className="main-sec-work-cont">
                    <>
                      <div className="sec-emp-det">
                        <div className="img-sec-emp">
                          <img src={cImg} />
                        </div>
                        <div className="desc-sec-emp">
                          <h5>{emp.jobRole}</h5>
                          <h4>{emp.company}</h4>
                          {emp.leaveDate === null ? <p>{emp.empType} | {moment(emp.joinDate).format('DD/MM/YYYY')} - present</p>: <p>{emp.empType} | {moment(emp.joinDate).format('DD/MM/YYYY')} - {moment(emp.leaveDate).format('DD/MM/YYYY')}</p>}
                        </div>
                      </div>
                    </>

                    <>
                      <div className="inner-sec-cont-work">
                        <p className="success" onClick={() => handleEditWork(emp)}><FontAwesomeIcon icon={faPencil} /></p>
                        <p className="danger" onClick={() => empDeleteById(emp._id)}><FontAwesomeIcon icon={faTrashAlt} /></p>
                      </div>
                    </>

                  </div>
                ))

              }
            </div>
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Education</h2>

                <button onClick={handleEducationOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              {/* <hr/> */}
              {/* <span className="hr-line"></span> */}
              {user?.education?.length === 0 ? <p>
                Details like course, university, and more, help recruiters
                identify your educational background.
              </p> : <>{user?.education?.map(edu => (

                <section className="main-sec-work-cont">
                  <>
                    <div className="edu-desk-view">
                      <h4>{edu?.course}, {edu?.specialization}</h4>
                      <h5>{edu?.instituteName}</h5>
                      <p>{edu?.passOut}, {edu?.courseType}</p>
                    </div>
                  </>
                  <>
                    <div className="inner-sec-cont-work">
                      <p className="success" onClick={() => handleEdu(edu)}><FontAwesomeIcon icon={faPencil} /></p>
                      <p className="danger" onClick={() => educationDeleteById(edu._id)}><FontAwesomeIcon icon={faTrashAlt} /></p>
                    </div>
                  </>
                </section>
              ))}</>}
            </div>
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Projects</h2>
                <button onClick={handleProjectOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              {user?.projects?.length === 0 ? <p>
                Stand out to employers by adding details about projects that you
                have done so far.
              </p> : <>{user?.projects?.map(pr => (

                <section className="main-sec-work-cont">
                  <>
                    <div className="edu-desk-view">
                      <h4>{pr?.projectName}, {pr?.roleInProject}</h4>
                      <h5>{pr?.projectDesc}</h5>
                      <p>{pr?.location}, {pr?.projectStatus}</p>
                    </div>
                  </>
                  <>
                    <div className="inner-sec-cont-work">
                      <p className="success" onClick={() => handleProjectEdit(pr)}><FontAwesomeIcon icon={faPencil} /></p>
                      <p className="danger" onClick={() => proDeleteById(pr._id)}><FontAwesomeIcon icon={faTrashAlt} /></p>
                    </div>
                  </>
                </section>
              ))}</>}

            </div>
            {/* <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Accomplishments</h2>
                <button>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              <p>
                Add you awards, recognition, appreciations, patent, white paper /
                research publication / journal entry, and certification.
              </p>
            </div> */}
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Career profile</h2>
                <button onClick={handleCarrerOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              {user?.careerPreferences[0]?.preferredLocation.length === 0 ? <p>
                Add details about your current and preferred job profile. This
                helps us personalise your job recommendations.
              </p> : <>{user?.careerPreferences?.map(pr => (

                <section className="main-sec-work-cont">
                  <>
                    <div className="edu-desk-view">
                      <h4>{pr?.employmentType}, {pr?.jobType}, {pr?.preferredShift}</h4>
                      <h5>{pr?.preferredRole}, Annual Salary: {pr?.preferredSalary} INR</h5>
                      <p className="loc-array-map-flex">Preffered Locations: {pr.preferredLocation?.map(loc => (<div>{loc}, </div>))}</p>
                    </div>
                  </>
                  <>
                    <div className="inner-sec-cont-work">
                      <p className="success"><FontAwesomeIcon icon={faPencil} onClick={() => handleCarrerEdit(pr)} /></p>
                      {/* <p className="danger"><FontAwesomeIcon icon={faTrashAlt} /></p> */}
                    </div>
                  </>
                </section>
              ))}</>}

            </div>
            <div className="edit_fields_container_right_side_card">
              <div>
                <h2>Personal details</h2>
                <button onClick={handlePersonalDetOpener}>
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </div>
              {user?.personalDetails[0]?.pinCode === "" ? <p>
                This information is important for employers to know you better.
              </p> : <>{user?.personalDetails?.map(pr => (

                <section className="main-sec-work-cont">
                  <>
                    <div className="edu-desk-view">
                      <h4>{user?.firstName}, {user?.lastName}, {user?.email}, {user?.contactNumber}</h4>
                      <h5>{pr?.address}, {pr?.homeTown}-{pr?.pinCode}</h5>
                      <p>Gender : {pr?.gender}, DOB: {moment(pr?.dob).format('DD-MM-YYYY')}</p>
                      <p>Career Break : {pr?.careerBreak}, Differently Abled: {pr?.differentlyAbled}, Marital Status: {pr?.maritalStatus}</p>
                    </div>
                  </>
                  <>
                    <div className="inner-sec-cont-work">
                      <p className="success"><FontAwesomeIcon icon={faPencil} onClick={() => handlePersonalEdit(pr)}/></p>
                  
                    </div>
                  </>
                </section>
              ))}</>}

            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      {user?.role === "user" ? renderUserSec() : renderRecruterSec()}
    </>
  );
};

export default UserProfile;
