import React, { useState } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useBasicDetMutation } from "../../slice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../slice/authSlice";

const ProfileSummary = ({ onClose, onSubmit, initialSummary }) => {
  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch();
  const [summary, setSummary] = useState(initialSummary || "");
  const [basicDet, {isLoading}] = useBasicDetMutation();

  const handleChange = (event) => {
    setSummary(event.target.value);
  };

  const handleSubmit = async () => {
    onSubmit(summary);
    // console.log(summary);

    const payload = {
      summary,
      resume: user?.basicDet[0]?.resume,
    };

    // console.log(payload);
    
    try {
      const res = await basicDet(payload).unwrap();
      dispatch(setCredentials({ userInfo: res, token: token }))
      // console.log(res);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="profile_summary_container modal_container">
      <div className="profile_summary_section modal_content">
        <div className="modal_x_button">
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="modal_text_content">
          <div className="resume_headline_text">
            <h1>Profile Summary</h1>
            <p>
              Give recruiters a brief overview of the highlights of your career,
              key achievements, and career goals to help recruiters know your
              profile better.
            </p>
          </div>
          <div className="modalinput_field">
            <textarea
              placeholder="Enter your headline here."
              value={summary}
              onChange={handleChange}
            />
            <div className="char_remaining">
              {250 - summary.length} character(s) left
            </div>
          </div>
          <div className="modal_button_section">
            <button className="modal_cancel_button" onClick={onClose}>
              Cancel
            </button>
            <button className="modal_submit_button" onClick={handleSubmit} disabled={isLoading}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSummary;
