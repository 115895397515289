import "./App.css";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import EmpLogin from "./containers/login/employee/EmpLogin";
import EmpRegister from "./containers/register/employee/EmpRegister";
import CandLogin from "./containers/login/candidate/CandLogin";
import CandRegister from "./containers/register/candidate/CandRegister";
import UserHome from "./containers/userHome/UserHome";
import Private from "./components/cards/Private";
import Home from "./screens/Home";
import UserProfile from "./containers/userHome/userProfile/UserProfile";
import { useSelector } from "react-redux";
import Jobs from "./containers/Jobs/Jobs";
import UserDetails from "./containers/UserDetails/UserDetails";
import AppliedJobs from "./containers/appliedJobs/AppliedJobs";
import DetailsJopApp from "./containers/appliedJobs/DetailsJobApp";
import SavedJobs from "./containers/savedJobs/SavedJobs";
import DetailsJob from "./containers/savedJobs/DetailsJob";
import Reco from "./containers/RecoJobs/Reco";
import DetailsPAge from "./containers/RecruiterCandidate/DetailsPage";
import StausUpdateDetailsPage from "./containers/RecruiterCandidate/StatusUpdateDetailPage";
import CandiDetails from "./containers/RecruiterCandidate/CandiDetails";
import SavedCandidate from "./containers/RecruiterCandidate/SavedCandidate";
import Forgot from "./containers/login/forgot/Forgot";
import Reset from "./containers/login/reset/Reset";

function App() {

  const user = useSelector((state) => state.auth.userInfo);
  // console.log(user, "from App");
  // Define router here
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <div className="App">
        <Outlet />
        <ToastContainer />
      </div>,
      children: [
        { path: "/", element: <Home /> },
        { path: "/emplogin", element: <EmpLogin /> },
        { path: "/empregister", element: <EmpRegister /> },
        { path: "/candlogin", element: <CandLogin /> },
        { path: "/candregister", element: <CandRegister /> },
        { path: "/forgot", element: <Forgot /> },
        { path: "/reset/reset-password", element: <Reset /> },
        {
          path: "",
          element: <Private />,
          children: [
            { path: "/", element: <Home /> },
            { path: "/user", element: <UserHome /> },
            { path: "/profile", element: <UserProfile /> },
            { path: "/jobs", element: <Jobs /> },
            { path: "/applied-jobs", element: <AppliedJobs /> },
            { path: "/saved-jobs", element: <SavedJobs /> },
            { path: "/candidate/:id", element: <UserDetails /> },
            { path: "/job/:id", element: <DetailsJopApp /> },
            { path: "/recommened-jobs", element: <Reco /> },
            { path: "/job/:id/details", element: <DetailsJob /> },
            { path: "/job/myjobs", element: <DetailsPAge /> },
            { path: "/job/myjobs/:id", element: <StausUpdateDetailsPage /> },
            { path: "/candidate/details/:id", element: <CandiDetails /> },
            { path: "/candidate/saved", element: <SavedCandidate /> },
          ],
        },
      ],
    },
  ]);

  return (
    <div className="App">
      {/* Set up RouterProvider */}
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}

export default App;
