// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile_nav_container {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:1rem;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 320px;
    width: 100%;
    max-width: 768px;
    font-size: 14px;
    z-index: 1;
}
.mobile_nav_logo_section {
    width: 150px;
    height: 40px;
}
.mobile_nav_logo_section img {
    width: 100%;
    height: 100%;
}

/* @media screen and(max-width: 768px) {
    .mobile_nav_container {
        padding: 1rem 8rem;
        background-color: aquamarine;
    }
} */

.mob_Icons_arrange{
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/containers/navbar/mobile_nav/MobileNav.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,UAAU;AACd;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;;;;;GAKG;;AAEH;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".mobile_nav_container {\r\n    background-color: #fff;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding:1rem;\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    min-width: 320px;\r\n    width: 100%;\r\n    max-width: 768px;\r\n    font-size: 14px;\r\n    z-index: 1;\r\n}\r\n.mobile_nav_logo_section {\r\n    width: 150px;\r\n    height: 40px;\r\n}\r\n.mobile_nav_logo_section img {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n/* @media screen and(max-width: 768px) {\r\n    .mobile_nav_container {\r\n        padding: 1rem 8rem;\r\n        background-color: aquamarine;\r\n    }\r\n} */\r\n\r\n.mob_Icons_arrange{\r\n    display: flex;\r\n    column-gap: 10px;\r\n    align-items: center;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
