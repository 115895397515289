import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./details.css";
import cImg from "../../assets/company.png"
import { generatePublicUrl } from '../../constants';
import { useJobDetByIdQuery, useJobDetQuery, useSaveCandidateMutation, useStatusJobMutation } from '../../slice/authApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setJobData, updateJobApplicant } from '../../slice/jobSlice';
import { setJob } from '../../slice/updateJobSlice';
import { toast } from "react-toastify";
import { FaBookmark, FaRegBookmark } from 'react-icons/fa6';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { setCredentials } from '../../slice/authSlice';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `none`,
    borderRadius: "10px",
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: "black" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: '#fff',
    flexDirection: 'row-reverse',
    borderRadius: "10px",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

function CandiDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { neDAta, jobId } = location.state;
    const [isInterested, setIsInterested] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState("");
    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const [statusJob, { isLoading: statusLoading }] = useStatusJobMutation();
    const { data:jobDetById, isLoading: isLoadingjobDet, error: jobError } = useJobDetByIdQuery(jobId, {
        skip: !jobId
    });
    const updateJob = useSelector(state => state.updateJob.updateJob);
    const [saveCandidate, { isLoading: saveLoading }] = useSaveCandidateMutation();
    const dispatch = useDispatch();


    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };


    
    useEffect(() => {
        // Ensure jobDet is not empty or undefined
        if (jobDetById) {
          dispatch(setJob(jobDetById));
          // console.log(jobDetById);
          
        }
      }, [dispatch, jobDetById, jobId]); 

    // console.log(jobDetById, "jobDetails");
    

    let emp = neDAta?.userId.employement?.filter(emp => emp.leaveDate === null);

    const statusMap = {
        applied: 20,
        'under review': 40,
        shortlisted: 60,
        rejected: 80,
        hired: 100,
    };


 
    // Assuming updateJob.applicant is an array
const findStatus = updateJob?.applicant?.some(app => app.userId === neDAta?.userId._id);

// console.log(findStatus, updateJob,"Matching Status");
const matchingApplicant = updateJob?.applicant?.find(app => app.userId === neDAta?.userId._id);

// Get status of the matching applicant
const matchingStatus = matchingApplicant?.status || 'No status available';
// console.log(matchingStatus);


useEffect(() => {
    // console.log(updateJob);
    
},[updateJob]);

    // console.log(neDAta);

    // let findStatus = updateJob.applicant.some(app => app.userId === neDAta?.userId._id);
    // console.log(findStatus);
    
    
    const progress = statusMap[matchingApplicant?.status || selectedStatus] || 0;

  
    const handleInterestChange = (response) => {
        setIsInterested(response);
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value); // Update selected status
    };

    const updateDataStateAction = async() => {
        let payload = {
            userId: neDAta?.userId?._id,
            jobId: jobId,
            status: selectedStatus
        }
        try {
            let res = await statusJob(payload).unwrap();
            // console.log(res);
            setSelectedStatus("");
         dispatch(setJob(res.job));
         toast.success(res.message);
            
        } catch (error) {
            // console.log(error);
            
        }
    }


    const handleSaveCandidate = async(data) => {
        let payload = {
            userId: data
        }
        try {
            let res = await saveCandidate(payload).unwrap();
            dispatch(setCredentials({ userInfo: res.savedCandidate, token: token }));
            // console.log(res);
            toast.success(res.message)
        } catch (error) {
            
        }
        
        
    }



    return (
        <>
            <div className="job-gg">
                <h2>User Details Page</h2>
                <Box className sx={{ width: '70%' }}>
                    <Typography variant="body1" gutterBottom style={{ color: "white" }}>
                        Progress ({matchingApplicant?.status} - {progress}%)
                    </Typography>
                    <LinearProgress
                        sx={{
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: 'white', // White color for the progress bar
                            }
                        }}
                        variant="determinate" value={progress}
                    />
                </Box>
            </div>
            <section className='AppliedJobs'>
                <div className='Applied-inner'>
                    <div className='applied-img'>
                        <img src={generatePublicUrl(neDAta?.userId?.profilePictures[0]?.img)} alt="Profile" />
                    </div>
                    <section className='mob-det-section-data'>
                    <div className='hide-in-desk-strong'>
                <div className='hide-in-desk-strong-img'><img src={generatePublicUrl(neDAta?.userId?.profilePictures[0]?.img)} alt="Profile" /></div>
                <div>User details in below</div>
                
                </div>
                </section>
                    <div className='applied-desc'>
                       <div className='bookMarkSavingData'> <h1>{neDAta?.userId.firstName} {neDAta.userId.lastName}</h1>  
                       <>
                    
                       {user.savedCandidate.includes(neDAta?.userId?._id) ? (
                                <FaBookmark className='bookMa' />
                            ) : (
                                <FaRegBookmark className='bookMa' onClick={() => handleSaveCandidate(neDAta?.userId?._id)}>Save</FaRegBookmark> // If not saved, show 'Save' button
                            )}
                        </>
                        
                        </div>
                        <p>{neDAta?.userId?.email}</p>
                        <br/>
                        
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
       <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{color: "black", marginTop: "10px"}}>Basic Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
                        <p>{neDAta?.userId?.basicDet[0]?.summary}</p>
                        <p>Contact Number: {neDAta?.userId?.contactNumber}</p>
                        <p>Current Location: {neDAta?.userId?.location}</p>
                        <p>Total Experience: {!emp[0]?.totalExp ? <>Not have any exprience</> : <>{emp[0]?.totalExp}</>}</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
                       
  <br/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography style={{color: "black", marginTop: "10px"}}>Skills</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p>{
                            neDAta?.userId?.keySkills.map(skil => (
                                <>{skil.skill}, &nbsp;</>
                            ))
                        }</p>
          </Typography>
        </AccordionDetails>
      </Accordion>



                     <br/>
                     <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography style={{color: "black", marginTop: "10px"}}>Employment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className='estaill'>
                            {neDAta?.userId?.employement.map(emp => (
                                <div className='est_card' key={emp.company}>
                                    <p>Company: <span>{emp.company}</span></p>
                                    <p>Role: <span>{emp.jobRole}</span></p>
                                    <p>Start date: <span>{emp.joinDate}</span></p>
                                    <p>Leaving Date: <span>{emp.leaveDate ? emp.leaveDate : "Present"}</span></p>
                                </div>
                            ))}
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>  

                        
                   <br/>
                   <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography style={{color: "black", marginTop: "10px"}}>Career Preferences</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className='estaill'>
                            {neDAta?.userId?.careerPreferences.map(emp => (
                                <div className='est_card' key={emp.preferredRole}>
                                    <p>Employment Type: <span>{!emp.employmentType? "employement type not added" :emp.employmentType }</span></p>
                                    <p>Job Type: <span>{emp.jobType === null ? "not added yet": emp?.jobType}</span></p>
                                    <p>Preferred Role: <span>{!emp.preferredRole ? "not added yet": emp.preferredRole}</span></p>
                                    <p>Preferred Location: <span>{emp.preferredLocation.length === 0 ? "not added location yet" :emp.preferredLocation.map(loc => (<>{loc},&nbsp;</>))}</span></p>
                                    <p>Expected Salary: <span>{emp?.preferredSalary === null ? 0 : emp.preferredSalary} INR</span></p>
                                </div>
                            ))}
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>  

                        
<br/>
                        <h3>Are you interested in this candidate?</h3>
                        <div className="interest-buttons">
                            <button onClick={() => handleInterestChange(true)}>Yes</button>
                            <button className='redClass' onClick={() => handleInterestChange(false)}>No</button>
                        </div>

                        {/* Conditionally render the button if the user is interested */}
                        {isInterested && (
                          <>
                             <h3>Proceed to Hire (set an action)</h3>
                             <div className="action-buttons-hire-change">
                            <select value={selectedStatus} onChange={handleStatusChange}>
                            <option value="">Select an action</option>    
                            <option value="under review">Under Review</option>
                            <option value="shortlisted">Shortlisted</option>
                            <option value="rejected">Rejected</option>
                            <option value="hired">Hired</option>
                        </select>
                        <button className="hire-button" onClick={updateDataStateAction}>Proceed</button>
                        </div>
                               
                          </>
                        )}
                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default CandiDetails;
