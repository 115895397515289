import React from "react";
import TextField from "@mui/material/TextField";
import "./MobileCandidateReg.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRegisterMutation } from "../../../../slice/authApiSlice";
import { setCredentials } from "../../../../slice/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebugValue, useState } from "react";
import Popup from "../../../popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const MobileCandidateReg = ({ onClose, onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("I am a compnay");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [location, setLocation] = useState("");
  const [contactNumber, setContactNumber] = useState();
  const [cName, setCname] = useState("");
  const [reqRole, setReqRole] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [register, { isLoading }] = useRegisterMutation();

  const createAccountHandler = () => {
    setModalOpen(true);
    const registerHandler = async () => {
      let payload = {
        firstName,
        lastName,
        email,
        password,
        status,
        location,
        contactNumber,
        cName,
        reqRole,
      };
      try {
        const res = await register(payload).unwrap();
        if (isLoading) {
          // console.log("lodin");
        } else {
          dispatch(setCredentials({ ...res.user }));
          navigate("/");
        }
      } catch (error) {}
    };
  };

  const handleModalClick = () => {
    setModalOpen(false);
  };

  const theme = createTheme({
    typography: {
      fontFamily: `"Kumbh Sans"`,
      fontSize: 12,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  return (
    <div className="me_reg_section">
      {modalOpen && (
        <Popup onClose={handleModalClick} onSubmit={handleModalClick} />
      )}
      <div className="me_reg_container">
        <div className="me_reg_heading">
          <div className="close_button_container">
            <button>
              <FontAwesomeIcon
                icon={faXmark}
                className="cross_icon"
                onClick={() => onClose()}
              />
            </button>
          </div>
          <h1>Create your recruiter account</h1>
          <p>Join our growing community of recruiters!</p>
        </div>
        <div className="me_reg_status_button_container">
          <p>Choose your company type</p>
          <div>
            <button>I’m a student</button>
            <button>I’m a fresher</button>
            <button>I’m experienced</button>
          </div>
        </div>
        <div className="me_reg_form">
          <ThemeProvider theme={theme}>
            <form>
              <TextField
                id="standard-multiline-flexible"
                label="First Name"
                multiline
                maxRows={4}
                variant="standard"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                style={{
                  width: "100%",
                  fontFamily: "Kumbh Sans, sans-serif",
                  fontSize: "14px",
                }}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Last Name"
                multiline
                maxRows={4}
                variant="standard"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{
                  width: "100%",
                  fontFamily: "Kumbh Sans, sans-serif",
                }}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Location"
                multiline
                maxRows={4}
                variant="standard"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                sx={{
                  width: "100%",
                  fontFamily: "Kumbh Sans, sans-serif",
                }}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Phone Number"
                multiline
                maxRows={4}
                variant="standard"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                sx={{
                  width: "100%",
                  fontFamily: "Kumbh Sans, sans-serif",
                }}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Email id"
                multiline
                maxRows={4}
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  width: "100%",
                  fontFamily: "Kumbh Sans, sans-serif",
                }}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Password"
                multiline
                maxRows={4}
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  width: "100%",
                  fontFamily: "Kumbh Sans, sans-serif",
                }}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Confirm Password"
                multiline
                maxRows={4}
                variant="standard"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{
                  width: "100%",
                  fontFamily: "Kumbh Sans, sans-serif",
                }}
              />
            </form>
          </ThemeProvider>

          <div className="me_reg_button_container">
            <button onClick={createAccountHandler}>Register</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileCandidateReg;
