import React, { useState, useEffect } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { setCredentials } from "../../slice/authSlice";
import { useCareerAddMutation } from "../../slice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditCarrer = ({ onClose, onSubmit, initialData }) => {
    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const [careerAdd, { isLoading }] = useCareerAddMutation();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        preferredLocation: initialData.preferredLocation || [],
        customLocation: initialData.customLocation || "",
        preferredRole: initialData.preferredRole || "",
        preferredSalary: initialData.preferredSalary || "",
        preferredShift: initialData.preferredShift || "",
        employmentType: initialData.employmentType || "",
        jobType: initialData.jobType || "",
    });

    // console.log(initialData);


    const locations = [
        "Delhi/NCR", "Remote", "Bengaluru", "Mumbai", "Pune",
        "Chennai", "Hyderabad", "Gurugram", "Noida",
        "Ahmedabad", "Kolkata", "Bhubaneswar"
    ];

    const handleLocationClick = (location) => {
        const isSelected = formData.preferredLocation.includes(location);
        const newLocations = isSelected
            ? formData.preferredLocation.filter((loc) => loc !== location)
            : [...formData.preferredLocation, location];
        setFormData((prevData) => ({
            ...prevData,
            preferredLocation: newLocations,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCustomLocationChange = (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            customLocation: value,
        }));
    };

    const handleSubmit = async () => {
        const { preferredLocation, customLocation } = formData;
        const updatedLocations = customLocation ? [...preferredLocation, customLocation] : preferredLocation;

        if (updatedLocations.length === 0) {
            toast.error("Please select at least one preferred location");
            return;
        }
        if (!formData.preferredRole) {
            toast.error("Please enter your preferred role");
            return;
        }
        if (!formData.preferredSalary) {
            toast.error("Please enter your preferred salary");
            return;
        } if (!formData.preferredShift) {
            toast.error("Please enter your preferred shift");
            return;
        }
        if (!formData.employmentType) {
            toast.error("Please enter employement type");
            return;
        } if (!formData.jobType) {
            toast.error("Please enter job type");
            return;
        }

        const res = await careerAdd({ ...formData, preferredLocation: updatedLocations }).unwrap();
        // console.log(res);

        dispatch(setCredentials({ userInfo: res, token: token }));
        onSubmit();
    };

    return (
        <div className="modal_container">
            <div className="modal_content">
                <div className="modal_x_button">
                    <button onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className="modal_text_content">
                    <div className="resume_headline_text">
                        <h1>Career Preferences</h1>
                        <p>Fill in your career preferences.</p>
                    </div>
                    <section className="edu-inner">
                        <div className="input_group">
                            <p>Preferred Location:</p>
                            <div className="location_options">
                                {locations.map((location) => (
                                    <button
                                        key={location}
                                        type="button"
                                        className={`location_button ${formData.preferredLocation.includes(location) ? 'selected' : ''}`}
                                        onClick={() => handleLocationClick(location)}
                                    >
                                        {location}
                                    </button>
                                ))}
                            </div>
                            <input
                                type="text"
                                name="customLocation"
                                placeholder="Type your own location"
                                value={formData.customLocation}
                                onChange={handleCustomLocationChange}
                            />

                            <input
                                type="text"
                                name="preferredRole"
                                placeholder="Preferred Role"
                                value={formData.preferredRole}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="preferredSalary"
                                placeholder="Preferred Salary"
                                value={formData.preferredSalary}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        handleInputChange(e);
                                    }
                                }}
                            />
                            <select
                                name="preferredShift"
                                value={formData.preferredShift}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Preferred Shift</option>
                                <option value="Day">Day</option>
                                <option value="Night">Night</option>
                                <option value="Flexible">Flexible</option>
                            </select>
                            <select
                                name="employmentType"
                                value={formData.employmentType}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Employment Type</option>
                                <option value="Full-time">Full-time</option>
                                <option value="Part-time">Part-time</option>
                            </select>
                            <select
                                name="jobType"
                                value={formData.jobType}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Job Type</option>
                                <option value="Permanent">Permanent</option>
                                <option value="Contractual">Contractual</option>
                            </select>
                        </div>
                    </section>
                    <div className="modal_button_section">
                        <button className="modal_cancel_button" onClick={onClose}>
                            Cancel
                        </button>
                        <button className="modal_submit_button" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCarrer;
