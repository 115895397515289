// src/slices/instituteSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  instituteData: [], // Initial state
};

const instituteSlice = createSlice({
  name: "institute",
  initialState,
  reducers: {
    setInstituteData: (state, action) => {
        const newData = Array.isArray(action.payload) ? action.payload : [action.payload];

        state.instituteData = [...state.instituteData, ...newData];
    },
  },
});

export const { setInstituteData } = instituteSlice.actions;
export default instituteSlice.reducer;
