import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./details.css";
import UserHeader from '../userHome/userHeader/UserHeader';
import { useAppliedJobsMyQuery, useApplyJobMutation, useSaveJobMutation } from '../../slice/authApiSlice';
import { setJobData, updateJobApplicant } from '../../slice/jobSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faBookmark, faSave } from "@fortawesome/free-solid-svg-icons";
import { FaBookmark, FaRegBookmark } from 'react-icons/fa6';
import { setCredentials } from '../../slice/authSlice';
import { useNavigate } from 'react-router-dom';
import { generatePublicUrl } from '../../config/image';
import cImg from "../../assets/company.png";

function DetailsPAge() {
    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const jobs = useSelector(state => state.job.jobData)
 
    const navigate = useNavigate();
   

    
   
    
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const jobsPerPage = 8;

    // Get current jobs to display
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

    // Function to handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(jobs.length / jobsPerPage);

    
    const visitJobData = (data) => {
        navigate(`/job/myjobs/${data._id}`, { state: { data } });
    }
    
   console.log(jobs);
   

    return (
        <div className='jobpage'>
            <div>
                <UserHeader />
                <h2 className='latest-header-job-design'>My applied jobs ({jobs?.length})</h2>
            </div>
          {jobs.length === 0 ? 
            <section className='job-outer1'>
                <h2>not yet apply any jobs</h2>
                <button>Apply now</button>
            </section>  :
            <>
              <section className='job-outer'>
                <div className='job-inner-page'>
                    {currentJobs?.map(jb => (
                        <div className='job-card' key={jb._id}>
                            <div className='jd-outer'>
                                <div className='job-img-det'>
                                    {jb?.companyDetails?.companyLogo === "" ? <img src={cImg} /> : <img src={generatePublicUrl(jb?.companyDetails?.companyLogo)}/> }
                                </div>
                                <div className='jd-outer-det-new'>
                                    <h2>{jb.title.length > 20 ? `${jb.title.slice(0, 20)}...` : jb.title}</h2>
                                    <p>{jb?.description.length > 52 ? `${jb?.description.slice(0, 52)}...` : jb?.description }</p>
                                    <div className='jdd-btn'>
                                        <button onClick={() => visitJobData(jb)}>See Details</button>
                                        <button>applied candidate: {jb?.applicant.length}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <div className="pagination">
                {[...Array(totalPages).keys()].map(pageNumber => (
                    <button
                        key={pageNumber + 1}
                        onClick={() => paginate(pageNumber + 1)}
                        className={currentPage === pageNumber + 1 ? 'active' : ''}
                    >
                        {pageNumber + 1}
                    </button>
                ))}
            </div>
            </>
        }
        </div>
    );
}

export default DetailsPAge;
