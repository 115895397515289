import React, { useState } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { setCredentials } from "../../slice/authSlice";
import { usePersonalAddMutation } from "../../slice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

const EditPersonal = ({ onClose, onSubmit, initialData = {} }) => {
    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const [personalAdd, { isLoading }] = usePersonalAddMutation();
    const dispatch = useDispatch();

  const [formData, setFormData] = useState({
  gender: initialData?.gender || "",
  maritalStatus: initialData?.maritalStatus || "",
  dob: moment(initialData.dob).format("YYYY-MM-DD") || "",
  category: initialData?.category || "",
  differentlyAbled: initialData?.differentlyAbled || "",
  carrerBreak: initialData?.careerBreak || "",
  address: initialData?.address || "",
  homeTown: initialData?.homeTown || "",
  pinCode: initialData?.pinCode || "",
  });

  // console.log(initialData);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async() => {
    const {
      gender,
      maritalStatus,
      dob,
      category,
      differentlyAbled,
      carrerBreak,
      address,
      homeTown,
      pinCode,
    } = formData;

    if (
      !gender ||
      !maritalStatus ||
      !dob ||
      !category ||
      !differentlyAbled ||
      !carrerBreak ||
      !address ||
      !homeTown ||
      !pinCode
    ) {
      toast.error("Please fill in all required fields");
      return;
    }else{
        const res = await personalAdd(formData).unwrap();
        dispatch(setCredentials({ userInfo: res, token: token }));
        onSubmit();
    }


  };

  return (
    <div className="modal_container">
      <div className="modal_content">
        <div className="modal_x_button">
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="modal_text_content">
          <div className="resume_headline_text">
            <h1>Personal Details</h1>
            <p>Please provide your personal details.</p>
          </div>
          <section className="edu-inner">
            <div className="input_group">
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>

              <select
                name="maritalStatus"
                value={formData.maritalStatus}
                onChange={handleInputChange}
              >
                <option value="">Select Marital Status</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
              </select>

              <input
                placeholder="Enter Date of Birth"
                className="textbox-n"
                name="dob"
                type="text"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                id="date"
                value={formData.dob}
                onChange={handleInputChange}
              />

              <select
                name="category"
                value={formData.category}
                onChange={handleInputChange}
              >
                <option value="">Select Category</option>
                <option value="general">General</option>
                <option value="sc">SC</option>
                <option value="st">ST</option>
                <option value="obc">OBC</option>
                <option value="other">Other</option>
              </select>

              <select
                name="differentlyAbled"
                value={formData.differentlyAbled}
                onChange={handleInputChange}
              >
                <option value="">Differently Abled?</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

              <select
                name="carrerBreak"
                value={formData.carrerBreak}
                onChange={handleInputChange}
              >
                <option value="">Career Break?</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

              <input
                type="text"
                name="address"
                placeholder="Enter Address"
                value={formData.address}
                onChange={handleInputChange}
              />

              <input
                type="text"
                name="homeTown"
                placeholder="Enter Home Town"
                value={formData.homeTown}
                onChange={handleInputChange}
              />

<input
  type="text"
  name="pinCode"
  placeholder="Enter Pin Code"
  value={formData.pinCode}
  onChange={(e) => {
    const value = e.target.value;
    // Allow only numeric values and restrict the length to 6 digits
    if (/^\d{0,6}$/.test(value)) {
      handleInputChange(e);
    }
  }}
/>
            </div>
          </section>
          <div className="modal_button_section">
            <button className="modal_cancel_button" onClick={onClose}>
              Cancel
            </button>
            <button className="modal_submit_button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPersonal;
