import React, { useState, useEffect } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { setCredentials } from "../../slice/authSlice";
import { useCareerAddMutation, useJobAddMutation, useJobStatusMutation } from "../../slice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setJobData } from "../../slice/jobSlice";

const JobEdit = ({ onClose, onSubmit, initialData }) => {
    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const [jobStatus, { isLoading }] = useJobStatusMutation();
    const dispatch = useDispatch();

    // console.log(initialData);
    

    const [formData, setFormData] = useState({
        jobRole: "",
        jobType: initialData.jobType,
        title: initialData.title,
        description: initialData.description,
        startDate: initialData.startDate,
        endDate: initialData.endDate,
        numPosition: initialData.numPosition,
        skillsRequired: initialData.skillsRequired,
        location: initialData.location,
        salary: initialData.salary,
        experienceStart: initialData.experienceStart,
        experienceEnd: initialData.experienceEnd,
        perks: "",
        companyDetails: user?.companyData?._id,
        status: initialData.status,
        skillInput: initialData.skillInput, 
        jobId: initialData._id
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSkillChange = (e) => {
        const { value } = e.target;
        if (value.includes(",")) {
          const newSkill = value.replace(",", "").trim();
          if (newSkill) {
            setFormData((prevData) => ({
              ...prevData,
              skillsRequired: [...prevData.skillsRequired, newSkill],
              skillInput: "",
            }));
          }
        } else {
          setFormData((prevData) => ({
            ...prevData,
            skillInput: value,
          }));
        }
      };
    
      const handleSkillRemove = (skillToRemove) => {
        setFormData((prevData) => ({
          ...prevData,
          skillsRequired: prevData.skillsRequired.filter(
            (skill) => skill !== skillToRemove
          ),
        }));
      };
    
      const handleSubmit = async () => {
        try {

            let payload ={
                jobId: initialData._id,
                status: formData.status
            }
          const res = await jobStatus(payload).unwrap();
          // console.log(res);
          
        dispatch(setJobData(res));
          onSubmit();
        } catch (error) {
          console.error("Failed to add job:", error);
        }
      };

    return (
        <div className="modal_container">
            <div className="modal_content">
                <div className="modal_x_button">
                    <button onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className="modal_text_content">
                    <div className="resume_headline_text">
                        <h1>Edit Job</h1>
                        <p>Fill all the field</p>
                    </div>
                    <section className="edu-inner">
                        <div className="input_group">
                        {/* <input
            type="text"
            name="jobRole"
            value={formData.jobRole}
            onChange={handleInputChange}
            placeholder="Enter job role"
          /> */}

           <select
            name="jobType"
            value={formData.jobType}
            onChange={handleInputChange}
            disabled
          > 
            <option value="">Enter job type</option>
            <option value="Exprienced">Exprienced</option>
            <option value="Remote">Remote</option>
            <option value="Fresher">Fresher</option>
            <option value="Startup">Startup</option>
            <option value="MNC">MNC</option>
            <option value="Product">Product</option>
            <option value="Healthcare">Healthcare</option>
            

          </select>
           <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            placeholder="Enter job title"
            disabled
          />
           <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Enter job description"
            disabled
          />
          <input
            type="text"
            name="startDate"
            value={formData.startDate}
            onChange={handleInputChange}
            onFocus={(e) => e.target.type = 'date'}
            onBlur={(e) => e.target.type = 'text'}
            id="date"
            placeholder="Enter start date"
            className="textbox-n"
            disabled
          />
            <input
            type="text"
            name="endDate"
            value={formData.endDate}
            onChange={handleInputChange}
            onFocus={(e) => e.target.type = 'date'}
            onBlur={(e) => e.target.type = 'text'}
            id="date"
            placeholder="Enter end date"
            className="textbox-n"
            disabled
          />
        <input
  type="text"
  name="numPosition"
  value={formData.numPosition}
  onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      handleInputChange(e);
    }
  }}
  placeholder="Enter number of positions required"
  disabled
/>

         <input
            type="text"
            name="skillInput"
            value={formData.skillInput}
            onChange={handleSkillChange}
            placeholder="Enter skill and press comma"
            disabled
          />
           <div className="skills-list">
            {formData.skillsRequired.map((skill, index) => (
              <div key={index} className="skill-item">
                {skill}
                <button
                  type="button"
                  className="remove-skill-button"
                  onClick={() => handleSkillRemove(skill)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            placeholder="Enter job location"
            disabled
          />
         <input
         disabled
  type="text"
  name="salary"
  value={formData.salary}
  onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      handleInputChange(e);
    }
  }}
  placeholder="Enter annual salary"
/>
<input
disabled
  type="text"
  name="experienceStart"
  value={formData.experienceStart}
  onChange={(e) => {
    const value = e.target.value;
    // Allow only numeric input
    if (/^\d*$/.test(value)) {
      handleInputChange(e);
    }
  }}
  placeholder="Enter minimum experience required"
/>

<input
disabled
  type="text"
  name="experienceEnd"
  value={formData.experienceEnd}
  onChange={(e) => {
    const value = e.target.value;
    // Allow only numeric input
    if (/^\d*$/.test(value)) {
      handleInputChange(e);
    }
  }}
  placeholder="Enter maximum experience required"
/>
          {/* <textarea
            name="perks"
            value={formData.perks}
            onChange={handleInputChange}
            placeholder="Enter perks"
          /> */}
        

          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
          > 
            <option value="">Set Oppening status</option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </select>


                        </div>
                    </section>
                    <div className="modal_button_section">
                        <button className="modal_cancel_button" onClick={onClose}>
                            Cancel
                        </button>
                        <button className="modal_submit_button" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobEdit;
