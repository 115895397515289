import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import "./Popup.css";
import logo from "../../assets/images/hire_form_logo.png";
import { useState } from "react";
import Otp from "../../components/otp/Otp";
import { useVerifyOtpMutation } from "../../slice/authApiSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../../slice/authSlice";
import Alert from "@mui/material/Alert";

const Popup = ({ onClose, onSubmit }) => {
  const [otp, setOtp] = useState("");
  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const resendOtp = useResendOtpMutaion();
  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token);
  const userId = user?._id;
  const upLift = (data) => {
    setOtp(data);
  };
  console.log(typeof otp);
  const otpVerifyHandler = async (e) => {
    e.preventDefault();
    try {
      const payload = { otp, userId };
      let res = await verifyOtp(payload).unwrap();
      // console.log(res);
      
      dispatch(setCredentials({ 
        userInfo: res.user, 
        token: token 
      }));
      navigate("/user");
    } catch (error) {
      alert("Invalid Otp");
    }
  };

  // console.log(otp);
  return (
    <div className="popup_section">
      <div className="popup_container">
        <div className="popup_left_side">
          <div className="popup_left_side_heading_section">
            <div className="popup_leftside_logo_container">
              <img src={logo} alt="logo" />
            </div>
            <div className="popup_leftside_text_container">
              <h4>CAREER</h4>
              <h4>EDUCATION</h4>
              <h4>SOCIAL</h4>
            </div>
          </div>
          <div className="popup_leftside_pnt">
            <p>Privacy Policy</p>
            <p>Terms & Conditions </p>
          </div>
        </div>
        <div className="popup_rightside">
          <div className="popup_rightside_text_container">
            <div className="close_button_container">
              <button>
                <FontAwesomeIcon
                  icon={faXmark}
                  className="cross_icon"
                  onClick={() => onClose()}
                />
              </button>
            </div>
            <h1>Verification</h1>
            <p>
              You will recive a <span>One Time Password</span> on your
              registered email
            </p>
          </div>
          <div className="popup_rightside_form_container">
            <form className="popup_rightside_form">
              <div className="popup_rightside_form_input_container">
                <label>Enter Otp</label>
                <Otp getOtp={upLift} />
              </div>
              <button onClick={otpVerifyHandler}>Verify</button>
            </form>
          </div>
          <div className="popup_rightside_bottom_text_section">
            <p>
              Haven't recieved the verification code OTP?
              <button>Resend Again</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
