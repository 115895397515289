import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import logo from "../../../assets/images/riso_hire_logo(new).png";
import "./MobileNav.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import EmpLogin from "../../login/employee/EmpLogin";
import CandLogin from "../../login/candidate/CandLogin";
import { Search } from "@mui/icons-material";
import SearchLogout from "./SearchLogout";

const MobileNav = ({ onClose, onSubmit }) => {
  const navigate = useNavigate();
  const [empModalOpen, setEmpModalOpen] = useState(false);
  const [candModalOpen, setCandModalOpen] = useState(false);
  const [showSearchData, setSearchData] = useState(false);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleEmpModalClick = () => {
    setEmpModalOpen(!empModalOpen);
  };
  const handleCandModalClick = () => {
    setCandModalOpen(!candModalOpen);
  };


  const handleMobSearchClick = () => {
    setSearchData(!showSearchData);
  }
  

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Candidate Login"}
              onClick={handleCandModalClick}
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Employee Login"}
              onClick={handleEmpModalClick}
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <CorporateFareOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Companies"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"About us"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className="mobile_nav_container">
       {showSearchData && <SearchLogout closeModal={() => setSearchData(false)}/>}
      {empModalOpen && (
        <EmpLogin
          onClose={handleEmpModalClick}
          onSubmit={handleEmpModalClick}
        />
      )}
      {candModalOpen && (
        <CandLogin
          onClose={handleCandModalClick}
          onSubmit={handleCandModalClick}
        />
      )}
      <div className="mobile_nav_logo_section" onClick={() => navigate("/")}>
        <img src={logo} alt="logo" />
      </div>
      <div>
        <React.Fragment>
        <div className="mob_Icons_arrange">
          <Search  onClick={handleMobSearchClick} />
          <MenuIcon onClick={toggleDrawer("left", true)} />
          </div>
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment>
      </div>
    </div>
  );
};

export default MobileNav;
