import React, { useState } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useBasicDetMutation, useFeedbackMutation } from "../../slice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../slice/authSlice";
import { toast } from "react-toastify";

const Feedback = ({ onClose, onSubmit, initialSummary }) => {
  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(1);
  const [feedback, {isLoading}] = useFeedbackMutation();

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const handleChangeRating = (event) => {
    setRating(event.target.value);
  };

  const handleSubmit = async () => {

    const payload = {
      message,
      rating
    };

    if(message === ""){
      toast.error("feedback is required");
    }else{
      try {
        const res = await feedback(payload).unwrap();
        onSubmit();
        // console.log(res);
      } catch (error) {
        // console.log(error);
      }
    }

    

  };

  return (
    <div className="profile_summary_container modal_container">
      <div className="profile_summary_section modal_content">
        <div className="modal_x_button">
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="modal_text_content">
          <div className="resume_headline_text">
            <h1>Feedback</h1>
            <p>
            We value your feedback! Your insights help us improve and serve you better. Whether it’s about our products, services, or your overall experience, we’d love to hear from you. Please share your thoughts, and let’s continue to grow together.
            </p>
          </div>
          <div className="modalinput_field">
          <select
        id="rating-select"
        value={rating}
        onChange={handleChangeRating}
        style={{ padding: '10px', fontSize: '16px' }}
      >
        <option value="" disabled>Select Rating</option>
        <option value="1">1 - Poor</option>
        <option value="2">2 - Fair</option>
        <option value="3">3 - Good</option>
        <option value="4">4 - Very Good</option>
        <option value="5">5 - Excellent</option>
      </select>
            <textarea
              placeholder="Enter your headline here."
              value={message}
              onChange={handleChange}
            />
            <div className="char_remaining">
              {250 - message.length} character(s) left
            </div>
          </div>
          <div className="modal_button_section">
            <button className="modal_cancel_button" onClick={onClose}>
              Cancel
            </button>
            <button className="modal_submit_button" onClick={handleSubmit} disabled={isLoading}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
