import React, { useState, useEffect } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useCurrentCompanyMutation, useEmployementEditMutation } from "../../slice/authApiSlice";
import { setCredentials } from "../../slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const EditWork = ({ onClose, onSubmit , initialData }) => {
  const [employementEdit, { isLoading }] = useEmployementEditMutation();
  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token);
  const [isEmployed, setIsEmployed] = useState(true);
  const [isCurrentCompany, setIsCurrentCompany] = useState(initialData.leaveDate === null ? true : false);
  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({
    company: initialData.company || "",
    joinDate: moment(initialData.joinDate).format("YYYY-MM-DD") || "",
    leaveDate: moment(initialData.leaveDate).format("YYYY-MM-DD") || "",
    jobRole: initialData.jobRole || "",
    empType: initialData.empType || "",
    totalExp: initialData.yearExp || "",
    totalExp1: initialData.monthExp || "",
    curentCompany: isCurrentCompany,
    aSalary: initialData.aSalary || "",
    skill: initialData.skill || [],
    jobProfile: initialData.jobProfile || "",
    noticePeriod: initialData.noticePeriod || "",
    experienceId: initialData._id
  });


  // console.log(initialData);
  

  const handleRadioChange = (e) => {
    const value = e.target.value === "yes";
    setIsEmployed(value);
    if (!value) {
      setFormData({
        company: "",
        joinDate: "",
        leaveDate: "",
        jobRole: "",
        empType: "",
        totalExp: "",
        totalExp1: "",
        curentCompany: false,
        aSalary: "",
        skill: "",
        jobProfile: "",
        noticePeriod: "",
      });
    }
  };

  const handleCurrentCompanyChange = (e) => {
    const value = e.target.value === "yes";
    setIsCurrentCompany(value);
    setFormData((prevData) => ({
      ...prevData,
      curentCompany: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async() => {
    // Check if any required fields are empty
    const {
      company,
      joinDate,
      leaveDate,
      jobRole,
      empType,
      totalExp,
      totalExp1,
      aSalary,
      skill,
      jobProfile,
      noticePeriod,
    } = formData;

    if (!company || !joinDate || !jobRole || !empType) {
      alert("Please fill in all required fields.");
      return;
    }

    if (isEmployed && !isCurrentCompany && !leaveDate) {
      alert("Please enter your leaving date.");
      return;
    }

    if (isCurrentCompany && (!totalExp || !totalExp1 || !aSalary || !skill || !jobProfile || !noticePeriod)) {
      alert("Please fill in all current company details.");
      return;
    }

    // console.log(formData);
    const res = await employementEdit(formData).unwrap();
    dispatch(setCredentials({ userInfo: res.updatedUser, token: token }));
    onSubmit();
    // onClose(); 
  };

  return (
    <div className="modal_container">
      <div className="modal_content">
        <div className="modal_x_button">
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="modal_text_content">
          <div className="resume_headline_text">
            <h1>Work Experience</h1>
            <p>
              Details like job title, company name, etc., help employers
              understand your work.
            </p>
          </div>
          <div className="modal_radio_section">
            <p>Are you employed?</p>
            <div className="modal_radio_container">
              <div className="radio_option">
                <input
                  type="radio"
                  value="yes"
                  name="employed"
                  onChange={handleRadioChange}
                  checked={isEmployed === true}
                />
                <p>Yes</p>
              </div>
              <div className="radio_option">
                <input
                  type="radio"
                  value="no"
                  name="employed"
                  onChange={handleRadioChange}
                  checked={isEmployed === false}
                />
                <p>No</p>
              </div>
            </div>
          </div>
          {isEmployed && (
            <>
              <div className="current_company_section">
                <p>Current Company?</p>
                <div className="modal_radio_container">
                  <div className="radio_option">
                    <input
                      type="radio"
                      value="yes"
                      name="currentCompany"
                      onChange={handleCurrentCompanyChange}
                      checked={isCurrentCompany === true}
                      disabled={!isEmployed}
                    />
                    <p>Yes</p>
                  </div>
                  <div className="radio_option">
                    <input
                      type="radio"
                      value="no"
                      name="currentCompany"
                      onChange={handleCurrentCompanyChange}
                      checked={isCurrentCompany === false}
                      disabled={!isEmployed}
                    />
                    <p>No</p>
                  </div>
                </div>
              </div>
              <div className="edu-inner">

                <div className="employment_details_section">

                  <div className="input_group">
                    <input
                      type="text"
                      name="company"
                      placeholder="Enter your company name"
                      value={formData.company}
                      onChange={handleInputChange}
                    />
                    <input
                      placeholder="Enter joining date"
                      className="textbox-n"
                      name="joinDate"
                      type="text"
                      onFocus={(e) => e.target.type = 'date'}
                      onBlur={(e) => e.target.type = 'text'}
                      id="date"
                      value={formData.joinDate}
                      onChange={handleInputChange}
                    />
                    {!isCurrentCompany && (<input
                      placeholder="Enter leaving date"
                      className="textbox-n"
                      name="leaveDate"
                      type="text"
                      onFocus={(e) => e.target.type = 'date'}
                      onBlur={(e) => e.target.type = 'text'}
                      id="date"
                      value={formData.leaveDate}
                      onChange={handleInputChange}
                    />)}
                    <input
                      type="text"
                      name="jobRole"
                      placeholder="Enter your job role"
                      value={formData.jobRole}
                      onChange={handleInputChange}
                    />

                    {/* Employment Type Dropdown */}
                    <select
                      name="empType"
                      value={formData.empType}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Employment Type</option>
                      <option value="Full-time">Full-time</option>
                      <option value="Part-time">Part-time</option>
                      <option value="Contract">Contract</option>
                      <option value="Internship">Internship</option>
                      <option value="Freelance">Freelance</option>
                    </select>

                    {!isCurrentCompany && (
                      <textarea
                        name="jobProfile"
                        placeholder="Describe your job profile"
                        value={formData.jobProfile}
                        onChange={handleInputChange}
                      />
                    )}
                    {
                      isCurrentCompany && (
                        <div className="inner-work">
                          <input
                            type="text"
                            name="totalExp"
                            placeholder="Enter years"
                            value={formData.totalExp}
                            onChange={handleInputChange}
                          /><span>years</span>
                          <input
                            type="text"
                            name="totalExp1"
                            placeholder="Enter months"
                            value={formData.totalExp1}
                            onChange={handleInputChange}
                          /><span>months</span>
                        </div>
                      )
                    }

                    {isCurrentCompany && (
                      <>
                        <input
                          type="text"
                          name="aSalary"
                          placeholder="Enter your annual salary"
                          value={formData.aSalary}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,4}$/.test(value)) {
                              handleInputChange(e);
                            }
                          }}
                        />
                        <input
                          type="text"
                          name="skill"
                          placeholder="Enter your skills"
                          value={formData.skill}
                          onChange={handleInputChange}
                        />
                        <textarea
                          name="jobProfile"
                          placeholder="Describe your job profile"
                          value={formData.jobProfile}
                          onChange={handleInputChange}
                        />
                        <input
                          type="text"
                          name="noticePeriod"
                          placeholder="Enter your notice period"
                          value={formData.noticePeriod}
                          onChange={handleInputChange}
                        />
                      </>
                    )}
                  </div>
                </div>



              </div>
            </>
          )}
          <div className="modal_button_section">
            <button className="modal_cancel_button" onClick={onClose}>
              Cancel
            </button>
            <button className="modal_submit_button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWork;
