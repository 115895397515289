import React, { useState } from "react";
import "./ModalGeneric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useBasicDetMutation } from "../../slice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../slice/authSlice";

const ResumeHeadline = ({ onClose, onSubmit, initialResumeSummary }) => {
  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [resumeSummary, setResumeSummary] = useState(initialResumeSummary || "");
  const [basicDet, {isLoading}] = useBasicDetMutation();
  const handleChange = (event) => {
    setResumeSummary(event.target.value);
  };

  const handleSubmit = async () => {
    onSubmit(resumeSummary);
    // console.log(resumeSummary);

    const payload = {
      summary: user?.basicDet[0]?.summary,
      resume: resumeSummary,
    };

    // console.log(payload);
    
    try {
      const res = await basicDet(payload).unwrap();
      dispatch(setCredentials({ userInfo: res, token: token }))
      // console.log(res);
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <div className="resume_headline_container modal_container">
      <div className="resume_headline_section modal_content">
        <div className="modal_x_button">
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="modal_text_content">
          <div className="resume_headline_text">
            <h1>Resume headline</h1>
            <p>
              Craft a succinct headline to captivate potential employers'
              attention with a brief introduction to your profile.(Alteast 5
              words)
            </p>
          </div>
          <div className="modalinput_field">
            <textarea placeholder="Enter your headline here."  value={resumeSummary} onChange={handleChange} />
            <div className="char_remaining">250 character(s) left</div>
          </div>
          <div className="modal_button_section">
            <button className="modal_cancel_button" onClick={onClose}>
              cancel
            </button>
            <button className="modal_submit_button" onClick={handleSubmit}>
              submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeHeadline;
