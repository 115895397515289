import React from "react";
import profileImg from "../../assets/images/hire_profile_img.jpg";
import "./Testimonials.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonialsObjectList from "../../objects/Testimonials";
import MobileTesti from "./mtestimonials/MobileTesti";
import { useFeedbackGetQuery } from "../../slice/authApiSlice";
import { generatePublicUrl } from "../../constants";

const Testimonials = () => {
  
  const { data:feedbackGet, isLoading: isLoadingjobDet, error: jobError } = useFeedbackGetQuery();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonials_section">
      {isLoadingjobDet ? (
        <p>Loading testimonials...</p>
      ) : jobError ? (
        <p>Error loading testimonials</p>
      ) : (
        <>
          <div className="testimonials_container">
            <h1 className="section_heading">Testimonials</h1>
            <div className="testimonials_card_container">
              <Slider {...settings} className="testimonial_slider">
                {feedbackGet?.feedback.map((obj, indx) => {
                  return (
                    <div className="testimonials_card" key={indx}>
                      <div className="t_card_img_container">
                        {obj.user?.profilePictures.length === 0 ? (
                          <img src={profileImg} alt="profile-img" />
                        ) : (
                          <img src={generatePublicUrl(obj.user?.profilePictures[0]?.img)} alt="profile-img" />
                        )}
                      </div>
                      <div className="t_card_description">
                        <p className="t_card_user_message">{obj.message}</p>
                        <div className="rating-adj">
                          <p className="t_card_user_designation">
                            {obj.user.firstName} {obj.user.lastName}
                          </p>
                          <p className="t_card_user_designation">Rating {obj.rating}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div className="mt_container_section">
            <MobileTesti />
          </div>
        </>
      )}
    </div>
  );
  
};

export default Testimonials;
