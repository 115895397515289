import React from "react";
import Header from "../header/Header";
import Navbar from "../navbar/Navbar";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="hero_container">
      <div className="hero_inner">
        <Navbar />
        <Header />
      </div>
    </div>
  );
};

export default Hero;
