import React from "react";
import "./TopCompanies.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import topcompOne from "../../assets/images/riso_hire_topcomp_1.gif";
import topCompaniesObjectList from "../../objects/TopCompanies";
import hire_arrow from "../../assets/images/hire_arrow.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MobileTopCompanies from "./mtopCompanies/MobileTopCompanies";
import { useTrendingDetQuery } from "../../slice/authApiSlice";
import { generatePublicUrl } from "../../config/image";

const TopCompanies = () => {

  const { data:trendingDet, isLoading: isLoadingTrending, error: trendingError } = useTrendingDetQuery();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // console.log(trendingDet);
  
  return (
    <div className="topcomp_section">
      <div className="topcomp_container">
        <div className="heading">
          <h1 className="section_heading">Trending Jobs in india</h1>
        </div>
        <div className="topcom_card_container">
          <Slider {...settings} className="topcomp_slider">
            {trendingDet?.data?.map((company, index) => {
              return (
                <div className="topcomp_card">
                  <div className="topcomp_card_content">
                    <div className="topcomp_card_heading_section">
                      <div className="topcomp_card_heading">
                        <h5>{company.title}</h5>
                        {/* <div>
                        <img src={hire_arrow} alt="arrow_right" />
                      </div> */}
                      </div>
                      <div className="topcomp_card_subheading">
                        <h6>{company.companyInfo[0].companyName}</h6>
                      </div>
                    </div>
                    <div className="topcomp_card_location_exp_container">
                      <div>
                        <p>Location : {company?.location}</p>
                      </div>
                      <div className="topcomp_card_exp">
                        <p>Experience : {company?.experienceStart} - {company?.experienceEnd} Years</p>
                      </div>
                    </div>
                    <div className="hire_arrow_section">
                      <img src={hire_arrow} alt="arrow_right" />
                    </div>
                  </div>
                  <div className="topcomp_card_image_container">
                    <img src={generatePublicUrl(company?.companyInfo[0].companyLogo)} alt="logo" />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="viewmore_button_container">
          <button>Explore More Opportunities</button>
        </div>
      </div>
      <div className="mtc_section">
        <MobileTopCompanies />
      </div>
    </div>
  );
};

export default TopCompanies;
