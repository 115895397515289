import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./details.css";
import UserHeader from '../userHome/userHeader/UserHeader';
import { useAppliedJobsMyQuery, useApplyJobMutation, useSavedCandidateMyQuery, useSavedJobsMyQuery, useSaveJobMutation } from '../../slice/authApiSlice';
import { setJobData, updateJobApplicant } from '../../slice/jobSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faBookmark, faSave } from "@fortawesome/free-solid-svg-icons";
import { FaBookmark, FaRegBookmark } from 'react-icons/fa6';
import { setCredentials } from '../../slice/authSlice';
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { generatePublicUrl } from '../../constants';

function SavedCandidate() {
    const user = useSelector((state) => state.auth.userInfo);
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const [candidate, setCandidate] = useState([]);  // Initialize with an empty array
    const { data: savedCandidate, isLoading, isError, refetch } = useSavedCandidateMyQuery();


    // console.log(savedCandidate);
    
  
    
    useEffect(() => {
        if (savedCandidate && Array.isArray(savedCandidate.savedCandidate)) {
            setCandidate(savedCandidate.savedCandidate);
        }
    }, [savedCandidate]);
    

    

    // const [currentPage, setCurrentPage] = useState(1);
    // const jobsPerPage = 8;


    // const indexOfLastJob = currentPage * jobsPerPage;
    // const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    // const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);


    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // const totalPages = Math.ceil(jobs.length / jobsPerPage);

    
    // const visitJobData = (data) => {
    //     navigate(`/job/${data._id}/details`, { state: { data } });
    // }
   

    return (
        <div className='jobpage'>
            <div>
                <UserHeader />
                <h2 className='latest-header-job-design'>My saved candidate ({candidate?.length})</h2>
            </div>
          {candidate.length === 0 ? 
            <section className='job-outer1'>
                <h2>not yet save any jobs</h2>
                <button>Save now</button>
            </section>  :
            <>
              <section className='job-outer78'>
                <div className='job-inner-page78'>
                    {candidate?.map(jb => (
                        <div className='candidate-card' key={jb._id}>
                             <div className='candidate-img'>
                                {jb?.profilePictures.length === 0 ? <p> {jb.firstName[0] + jb.lastName[0]}</p> :  <img src={generatePublicUrl(jb?.profilePictures[0]?.img)}/>}
                               
                             </div>
                             <div className='candidate-description'>
                                 <h2>{jb.firstName} {jb.lastName}</h2>
                                 <p>{jb.email}</p>
                                 <p>{jb?.contactNumber}</p>
                             </div>
                        </div>
                    ))}
                </div>
            </section>
            {/* <div className="pagination">
                {[...Array(totalPages).keys()].map(pageNumber => (
                    <button
                        key={pageNumber + 1}
                        onClick={() => paginate(pageNumber + 1)}
                        className={currentPage === pageNumber + 1 ? 'active' : ''}
                    >
                        {pageNumber + 1}
                    </button>
                ))}
            </div> */}
            </>
        }
        </div>
    );
}

export default SavedCandidate;
