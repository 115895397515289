import React, { useState, useEffect } from "react";
import "./UserFeeds.css";
import prof_image from "../../../assets/images/prof_image.jpg";
import { HiArrowRight } from "react-icons/hi2";
import { useLandingPageQuery } from "../../../slice/adminDataApiSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { generatePublicUrl } from "../../../config/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import camera_icon from "../../../assets/images/camera_icon.svg";
import cIMg from "../../../assets/company.png";
import Bimg from "../../../assets/images/blog.jpg";
import eduImg from "../../../assets/images/edu.jpg";
import mba from "../../../assets/images/mba.jpg"

import UserHeader from "../userHeader/UserHeader";
import { useJobAllQuery, useRecJobQuery, useUplaodProfileAddMutation } from "../../../slice/authApiSlice";
import { setJobData } from "../../../slice/jobSlice";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../../../slice/authSlice";

const UserFeeds = () => {
  const { data, isLoading } = useLandingPageQuery();
  const {data: jobDetails, isLoading: jobLoading} = useJobAllQuery();
  const {data: recJob, isLoading: recLoading} = useRecJobQuery();
  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token);
  const jobs = useSelector((state) => state.job.jobData);
  const [isHovered, setIsHovered] = useState(false);
  const [profilePictures, setProfilePictures] = useState([]);
  const [uplaodProfileAdd, { isLoading: profileLoading }] = useUplaodProfileAddMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleProfilePictures = (e) => {
    setProfilePictures([e.target.files[0]]);
  };

  const updateNow = async () => {
    if (profilePictures.length === 0) return;

    const form = new FormData();
    form.append("profilePictures", profilePictures[0]);

    try {
      let res = await uplaodProfileAdd(form).unwrap();
      setProfilePictures([]);
      dispatch(setCredentials({userInfo: res.user, token:token}))
      // console.log(res);
    } catch (error) {
      // console.log(error);
    }
  };


  // console.log(jobs, "landing");
  

  const profileSections = {
    resume: !!user?.basicDet?.[0]?.resume,
    profileSummary: !!user?.basicDet?.[0]?.summary,
    skills: user?.keySkills?.length > 0,
    workExperience: user?.employement?.length > 0,
    education: user?.education?.length > 0,
    projects: !!user?.projects?.length,
    accomplishments: !!user?.accomplishments?.length,
    careerProfile: !!user?.careerPreferences?.length,
    personalDetails: !!user?.personalDetails,
  }

  const formatDate = (date) => {
    let dateFormat1 = moment(date).format("D-MM-YYYY");
    return <div>{dateFormat1}</div>; // 23-08-2022
  };

  if (!isLoading) {
    // console.log(data[0]?.blog?.date);
  }


  // console.log(jobDetails, jobs, recJob);

   let userId = user?._id;

  //  console.log(userId);
   
  
 

  let edu = user?.education?.filter(edu => edu.category === 'graduate');
  let emp = user?.employement?.filter(emp => emp.leaveDate === null);
  // console.log(emp);
  
  const visitToReco = () => {
     navigate("/recommened-jobs")
  }

  const updateDattttaa = () => {
    navigate("/profile")
  }

  const visitJobData = (data) => {
    navigate(`/job/${data._id}/details`, { state: { data } });
}

const renderDesktopView = () => {
    return(
      <div className="main_user">
      <div>
        <UserHeader />
      </div>
      <div className="user_feeds_container">
        <div className="user_feeds_container_left_side">
    
        <Box position="relative" display="inline-flex">
    <CircularProgress
      color="success"
      variant="determinate"
      value={user?.profileCompletion || 0}
      style={{ width: '200px', height: '200px' }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
    <div
            className="user-profile-img"
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {!isHovered ? (
              <>{user?.profilePictures.length === 0 ?   <p className="prof_icon">
                {user.firstName[0] + user.lastName[0]}
              </p> : <img src={generatePublicUrl(user?.profilePictures[0].img)}/>}</>
            ) : (
              <div className="add_image_button">
                
                <label for="file-upload">
    <img src={camera_icon} alt="camera" />
    Add Image
    <input id="file-upload" type="file"   name="profilePictures" onChange={handleProfilePictures} style={{display: "none"}} />
    </label>
                
              </div>
            )}
          </div>
    </Box>
    </Box>
         
          <div className="user-up-name">
            <h6>{user.firstName + " " + user.lastName}</h6>
            {!edu[0]?.course  ?  <p>Add graducation details</p> :  <p>{edu[0]?.course} &nbsp; ({edu[0]?.specialization})</p>}
           
          </div>
    
          <div className="user-up-loc">
            <div className="use-inner">
              <p>Experience</p>
              {!emp[0]?.totalExp ? <h6>Add total Exprience</h6> : <h6>{emp[0]?.totalExp}</h6>}
              {/* <h6>{emp[0]?.totalExp}</h6> */} 
            </div>
            <div className="use-inner">
              <p>Location</p>
              <h6 style={{textTransform: "capitalize"}}>{user?.location}</h6>
            </div>
          </div>
          <div className="user-btn-update">
            <p>Profile {user?.profileCompletion}% Completed</p>
            {profilePictures.length !== 0 ? <button onClick={updateNow}>Upload profile picture</button> : <button onClick={updateDattttaa}>Update Now</button>}
          </div>
        </div>
        <div className="user_feeds_container_right_side">
          <div className="industry_experts_container">
            <div className="industry_experts_heading_section">
              <h3>Connect with industry experts</h3>
              <p>100+ Industries and Academia Experts</p>
            </div>
            <div className="experts_profile_card_container">
              {isLoading ? (
                <div>loading</div>
              ) : (
                data[0]?.ourCounciller?.map((counciller) => {
                  return (
                    <div className="experts_profile_card">
                      <div className="experts_profile_image">
                        <img
                          src={generatePublicUrl(counciller.image)}
                          alt="profile"
                        />
                      </div>
                      <p>{counciller.name}</p>
                    </div>
                  );
                })
              )}
              <div className="experts_profile_card_button">
                <button>More</button>
              </div>
            </div>
          </div>
          <div className="recommended_jobs_container">
            <div className="recommended_jobs_heading">
              <h3>Recommended jobs for you</h3>
              <button onClick={visitToReco}>View all</button>
            </div>
            <div className="recommended_jobs_card_container">
             {recJob?.jobs?.slice(-2)?.map(job => {
              return(
                <div className="rec-card">
                <div className="logo-area-job">
                {job?.companyDetails?.companyLogo === "" ? <img src={cIMg}/> : <img src={generatePublicUrl(job?.companyDetails?.companyLogo)}/>}
                </div>
                <div className="description-job-card">
                  <h5>{job?.title}</h5>
                  <h6>{job?.companyDetails?.companyName}</h6>
                  <div className="inner-job">
                    <p>
                      <span>Location : </span>&nbsp;
                      <span className="bold-lc">
                        Bangalore, Karnataka, India (Hybrid)
                      </span>
                    </p>
                    <p>
                      <span>Experience : </span>&nbsp;
                      <span className="bold-lc">{job?.experienceStart} - {job?.experienceEnd} Years</span>
                    </p>
                  </div>
                  <div className="job-arrow-btn">
                    <p>
                      <HiArrowRight onClick={() => visitJobData(job)} />
                    </p>
                  </div>
                </div>
              </div>
              )
             })}
            </div>
          </div>
          <div className="top_company_jobs_container">
            <div className="recommended_jobs_heading1">
              <h3>Jobs from top companies</h3>
              <button>View all</button>
            </div>
            <div className="recommended_jobs_card_container">

            {jobs?.slice(0, 2)?.map(jb => (
  jb?.companyDetails.type === "MNC" && (
    <div className="rec-card" key={jb._id}>
      <div className="logo-area-job">
        <img src={generatePublicUrl(jb?.companyDetails?.companyLogo)} alt={jb?.companyDetails?.companyName} />
      </div>
      <div className="description-job-card">
        <h5>{jb.title}</h5>
        <h6>{jb?.companyDetails?.companyName}</h6>
        <div className="inner-job">
          <p>
            <span>Location: </span>&nbsp;
            <span className="bold-lc">{jb?.location}</span>
          </p>
          <p>
            <span>Experience: </span>&nbsp;
            <span className="bold-lc">{jb?.experienceStart} - {jb?.experienceEnd} Years</span>
          </p>
        </div>
        <div className="job-arrow-btn">
          <p>
            <HiArrowRight onClick={() => visitJobData(jb)} />
          </p>
        </div>
      </div>
    </div>
  )
))}

            
            </div>
          </div>
    
          {/*  */}
    
          <div className="top_company_jobs_container">
            <div className="recommended_jobs_heading1">
              <h3>Recent Blogs</h3>
              <button>View all</button>
            </div>
            <div className="recommended_blog_card_container">
              {isLoading ? (
                <div>loader</div>
              ) : (
                data[0].blog.slice(-3).map((blog) => (
                  <>
                  <div className="blog-data">
                    <div className="blog-img-data">
                      <img src={generatePublicUrl(blog?.image)}></img>
                    </div>
                    <div className="blog-desc-data">
                      <h5>{blog.title}</h5>
                      <p>{blog.description.slice(0, 63)}...</p>
                      <p>{moment(blog.createdAt).format("DD/MM/YYYY")}</p>
                    </div>
                    <div className="blog-arrow-btn">
                      <p>
                        <HiArrowRight />
                      </p>
                    </div>
                  </div>
              
                  </>
                 ))
              )}
            </div>
          </div>

          
    
          {/*  */}
        </div>
      </div>
    </div>
    )
}



const renderMobileView  = () => {
  return(
    <div className="mobile-headdder">


        <div className="my-mobile-header-profile">
             <div className="my-mobileeeeeeee">
             <div className="profile-mobile-ic">
             <Box position="relative" display="inline-flex">
    <CircularProgress
      variant="determinate"
      color="success"
      value={user?.profileCompletion || 0}
      style={{ width: '70px', height: '70px' }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
    <div
            className="my-mob-head-pr"
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {!isHovered ? (
              <>{user?.profilePictures.length === 0 ?   <p className="prof_icon">
                {user.firstName[0] + user.lastName[0]}
              </p> : <img src={generatePublicUrl(user?.profilePictures[0].img)}/>}</>
            ) : (
              <div className="add_image_button">
                
                <label for="file-upload">
    <img src={camera_icon} alt="camera" />
    <input id="file-upload" type="file"   name="profilePictures" onChange={handleProfilePictures} style={{display: "none"}} />
    </label>
                
              </div>
            )}
          </div>
    </Box>
    </Box>
             </div>
             <div className="my-mob-head-desc">
              <h2>Welcome,</h2>
              <p>{user?.firstName + user?.lastName}</p>
             </div>
             </div>
             {profilePictures.length !== 0 ? <button onClick={updateNow}>Upload</button> : <button onClick={updateDattttaa}>Update Now</button>}
             
        </div>
   
          <div className="industry_experts_container">
            <div className="industry_experts_heading_section">
              <h3>Connect with industry experts</h3>
              <p>100+ Industries and Academia Experts</p>
            </div>
            <div className="experts_profile_card_container">
              {isLoading ? (
                <div>loading</div>
              ) : (
                data[0]?.ourCounciller?.slice(0, 3)?.map((counciller) => {
                  return (
                    <div className="experts_profile_card">
                      <div className="experts_profile_image">
                        <img
                          src={generatePublicUrl(counciller.image)}
                          alt="profile"
                        />
                      </div>
                      <p>{counciller.name}</p>
                    </div>
                  );
                })
              )}
              <div className="experts_profile_card_button">
                <button>More</button>
              </div>
            </div>
          </div>
          <div className="recommended_jobs_heading1">
              <h3>Recommended jobs for you</h3>
              <button onClick={visitToReco}>View all</button>
            </div>
     
            <div className="recommended_jobs_card_container">
             {recJob?.jobs?.slice(-2)?.map(job => {
              return(
                <div className="rec-card">
                <div className="logo-area-job">
                  <img src={cIMg}/>
                </div>
                <div className="description-job-card">
                  <h5>{job?.title}</h5>
                  <h6>{job?.companyDetails?.companyName}</h6>
                  <div className="inner-job">
                    <p>
                      <span>Location : </span>&nbsp;
                      <span className="bold-lc">
                        Bangalore, Karnataka, India (Hybrid)
                      </span>
                    </p>
                    <p>
                      <span>Experience : </span>&nbsp;
                      <span className="bold-lc">{job?.experienceStart} - {job?.experienceEnd} Years</span>
                    </p>
                  </div>
                  <div className="job-arrow-btn">
                    <p>
                      <HiArrowRight onClick={() => visitJobData(job)} />
                    </p>
                  </div>
                </div>
              </div>
              )
             })}
            </div>


            <div className="recommended_jobs_heading1">
              <h3>Jobs from top companies</h3>
              <button>View all</button>
            </div>
          {/*  */}
   
          <div className="recommended_jobs_card_container">

          {jobs?.slice(0, 2)?.map(jb => (
  jb?.companyDetails.type === "MNC" && (
    <div className="rec-card">
    <div className="logo-area-job">
    <img src={generatePublicUrl(jb?.companyDetails?.companyLogo)} alt={jb?.companyDetails?.companyName} />
    </div>
    <div className="description-job-card">
    <h5>{jb.title}</h5>
    <h6>{jb?.companyDetails?.companyName}</h6>
      <div className="inner-job">
        <p>
          <span>Location : </span>&nbsp;
          <span className="bold-lc">
            {jb?.location}
          </span>
        </p>
        <p>
          <span>Experience : </span>&nbsp;
          <span className="bold-lc">{jb?.experienceStart} - {jb?.experienceEnd} Years</span>
        </p>
      </div>
      <div className="job-arrow-btn">
        <p>
          <HiArrowRight onClick={() => visitJobData(jb)} />
        </p>
      </div>
    </div>
  </div>
   )
  ))}
             
            </div>


            <div className="recommended_jobs_heading1">
              <h3>Recent Blogs</h3>
              <button>View all</button>
            </div>

            <div className="recommended_blog_card_container">
              {isLoading ? (
                <div>loader</div>
              ) : (
                 data[0].blog.map((blog) => (
                  <>
                 
                 
                 <div className="blog-data">
                    <div className="blog-img-data">
                      <img src={generatePublicUrl(blog?.image)}></img>
                    </div>
                    <div className="blog-desc-data">
                      <h5>{blog.title}</h5>
                      <p>{blog.description.slice(0, 63)}...</p>
                      <p>{moment(blog.createdAt).format("DD/MM/YYYY")}</p>
                    </div>
                    <div className="blog-arrow-btn">
                      <p>
                        <HiArrowRight />
                      </p>
                    </div>
                  </div>
                  </>
               ))
              )}
            </div>
  </div>
  )
}
 
  const imgList = [prof_image, prof_image, prof_image, prof_image, prof_image];
  return (
      <>
      {renderDesktopView()}
      {renderMobileView()}
      </>
  );
};

export default UserFeeds;
